import React from "react";
import { Box } from "@mui/material";
import DashboardCard from "./components/DashboardCard";
import useDashboard from "./hooks/useDashboard";
import Grid from "./components/atomics/Grid";

const DashboardView = () => {
  // const changeLocation = stores.ui.getState().update;
  // changeLocation((data) => {
  //   data.pageName = "Dashboard";
  //  // data.breadcrump = [{ label: "Home", link: "" }];
  // });

  const { cards } = useDashboard();

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid container spacing={1}>
        {cards.map((card) => (
          <DashboardCard key={card.path} {...card} />
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardView;

import React, { useEffect, useState } from "react";
import Stack from "../common/components/atomics/Stack";
import TextField from "../common/components/atomics/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { stores, useStores } from "../stores/stores";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import Grid from "../common/components/atomics/Grid";
import Paper from "../common/components/atomics/Paper";
import { StoresDataType } from "../stores/storeTypes";
import useApi from "../common/hooks/useApi";
import { ITemplateDocumentation } from "../model/templateDocumentation";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

const TemplateDocumentationDetail = (props: any) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { updateTemplateDocumentation } = useApi();
  const changeRemote = stores.remote.getState().update;
  const docTemplateLocal = useStores(
    "remote",
    (data: StoresDataType["remote"]) => data.documentationTemplates!.find((d) => d.id === id)!
  );

  // Add useState hooks for each new field
  const [templateName, setTemplateName] = useState(docTemplateLocal.templateName);
  const [description, setDescription] = useState(docTemplateLocal.description);
  const [linkDescription, setLinkDescription] = useState(docTemplateLocal.linkDescription);
  const [toggleExtended, setToggleExtended] = useState(docTemplateLocal.toggleExtended);
  const [toggleStation, setToggleStation] = useState(docTemplateLocal.toggleStation);
  const [useVehicleManager, setUseVehicleManager] = useState(docTemplateLocal.useVehicleManager);
  const [useMeasurement, setUseMeasurement] = useState(docTemplateLocal.useMesurement);
  const [vehicles, setVehicles] = useState(docTemplateLocal.vehicles);

  if (!docTemplateLocal) return <Typography>Kein Template geladen</Typography>;

  const handleSave = async () => {
    const updatedTemplate = {
      ...docTemplateLocal,
      templateName,
      description,
      linkDescription,
      toggleExtended,
      toggleStation,
      useVehicleManager,
      useMeasurement,
      vehicles,
    } as ITemplateDocumentation;

    changeRemote((data: StoresDataType["remote"]) => {
      const docIndex = data.documentationTemplates!.findIndex((d) => d.id === id);
      if (docIndex !== -1) {
        data.documentationTemplates![docIndex] = updatedTemplate;
      }
    });
    await updateTemplateDocumentation(updatedTemplate as ITemplateDocumentation);
    navigate("/account");
  };

  console.log(vehicles);

  return (
    <Paper variant="outlined" m={2}>
      <Grid container p={2}>
        <Grid item xs={12} mb={2}>
          <Typography>Template Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <TextField
              size="small"
              name="templateName"
              label="Template Name"
              value={templateName}
              fullWidth
              onChange={(e) => setTemplateName(e.target.value)}
            />
            <TextField
              size="small"
              name="description"
              label="Description"
              value={description}
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              size="small"
              name="linkDescription"
              label="Link Description"
              value={linkDescription}
              fullWidth
              onChange={(e) => setLinkDescription(e.target.value)}
            />
            {/* Add switches for boolean values */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={toggleExtended}
                    onChange={(e) => setToggleExtended(e.target.checked)}
                    name="toggleExtended"
                    color="primary"
                  />
                }
                label="Vorlage enthält Fahrzeuge von anderen Wachen"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={toggleStation}
                    onChange={(e) => setToggleStation(e.target.checked)}
                    name="toggleStation"
                    color="primary"
                  />
                }
                label="Vorlage mit Auswahl der Wache"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={useVehicleManager}
                    onChange={(e) => setUseVehicleManager(e.target.checked)}
                    name="useVehicleManager"
                    color="primary"
                  />
                }
                label="Vorlage benutzt das Fahrzeugmodul"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={useMeasurement}
                    onChange={(e) => setUseMeasurement(e.target.checked)}
                    name="useMeasurement"
                    color="primary"
                  />
                }
                label="Vorlage benutzt das Messmodul"
              />
            </FormGroup>

            {/* Vehicles list */}
            {vehicles &&
              vehicles.map((vehicle, index) => (
                <div key={index}>
                  <TextField
                    size="small"
                    name={`vehicleName-${index}`}
                    label="Vehicle Name"
                    value={vehicle.name}
                    fullWidth
                    onChange={(e) => {
                      const newVehicles = [...vehicles];
                      newVehicles[index].name = e.target.value;
                      setVehicles(newVehicles);
                    }}
                  />
                  {/* Add other vehicle fields similarly */}
                </div>
              ))}
            <Button variant="contained" onClick={() => handleSave()}>
              Aktualisieren
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Zurück
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateDocumentationDetail;

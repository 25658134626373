import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navigator from "./Navigator";

import Header from "./Header";
import { Outlet } from "react-router-dom";
import { stores, useStores } from "../stores/stores";
import { Button, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dbVersion } from "./../stores/stores";
import IconButton from "./components/atomics/IconButton";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Version: beta_"}
      {dbVersion}
      {" | Oliver Schmid "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const drawerWidth = 256;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const snackPack = useStores("ui", (data) => data.snackPack);
  const snackOpen = useStores("ui", (data) => data.snackOpen);
  const snackMessage = useStores("ui", (data) => data.snackMessage);
  const changeSnack = stores.ui.getState().update;

  React.useEffect(() => {
    if (snackPack && snackPack.length && !snackMessage) {
      // Set a new snack when we don't have an active one
      changeSnack((data) => {
        data.snackMessage = { ...snackPack[0] };
        data.snackPack = data.snackPack.slice(1);
        data.snackOpen = true;
      });
    } else if (snackPack && snackPack.length && snackMessage && snackOpen) {
      // Close an active snack when a new one is added
      changeSnack((data) => {
        data.snackOpen = false;
      });
    }
  }, [snackPack, snackMessage, snackOpen]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    changeSnack((data) => {
      data.snackOpen = false;
    });
  };

  const handleExited = () => {
    changeSnack((data) => {
      data.snackMessage = undefined;
    });
  };

  return (
    <Box>
      <CssBaseline />
      <Header />
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Box component="main" sx={{ flex: 1, py: 2, px: 4, bgcolor: "#eaeff1", paddingTop: "20px" }}>
          <Outlet />
        </Box>
        <Box component="footer" sx={{ p: 1, bgcolor: "#eaeff1" }}>
          <Copyright />
        </Box>
        <Snackbar
          key={snackMessage ? snackMessage.key : undefined}
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          message={snackMessage ? snackMessage.message : undefined}
          action={
            <IconButton color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
}

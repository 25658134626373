import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "./components/atomics/Box";
import Paper from "./components/atomics/Paper";
import Typography from "./components/atomics/Typography";
import Grid from "./components/atomics/Grid";
import Member from "../Management/Member";
import useManagementApi from "./hooks/useManagementApi";
import Button from "./components/atomics/Button";
import Stack from "./components/atomics/Stack";
import FireDepartmentView from "./../Management/FireDepartmentView";
import TabContext from "./components/atomics/TabContext";
import AppBar from "./components/atomics/AppBar";
import { TabList } from "@mui/lab";
import Tab from "./components/atomics/Tab";
import Toolbar from "./components/atomics/Toolbar";
import TabPanel from "./components/atomics/TabPanel";
import { stores, useStores } from "../stores/stores";
import Device from "../Management/Device";
import { StoresDataType } from "../stores/storeTypes";
import FireDepartmentManageView from "../Management/FireDepartmentManageView";

const ProfileView = (props: any) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { copyTemplatesMesureDevice } = useManagementApi();
  const member = useStores("cache", (data) => data.member);
  const device = useStores("cache", (data) => data.device);
  const tab = useStores("ui", (data) => data.selectedAccountTab);
  const changeUi = stores.ui.getState().update;

  const handleChangeTab = (event: any, newValue: string) => {
    changeUi((data: StoresDataType["ui"]) => {
      data.selectedAccountTab = newValue;
    });
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!user) return <div>no user ...</div>;

  return (
    isAuthenticated && (
      <Grid>
        <TabContext value={tab}>
          <Grid container>
            <AppBar position="sticky" color="inherit">
              <Toolbar>
                <Grid item xs={12}>
                  {member && (
                    <TabList onChange={handleChangeTab} aria-label="lab">
                      <Tab label="Profil" value="1" />
                      <Tab label="Feuerwehr Einstellungen" value="2" />
                      <Tab label="Feuerwehr Verwalten" value="3" />
                    </TabList>
                  )}
                  {!member && !device && (
                    <TabList onChange={handleChangeTab} aria-label="lab">
                      <Tab label="Profil" value="1" />
                      <Tab label="Feuerwehr Einstellungen" value="2" />
                      <Tab label="Feuerwehr Verwalten" value="3" />
                    </TabList>
                  )}
                  {!member && device && (
                    <TabList onChange={handleChangeTab} aria-label="lab">
                      <Tab label="Profil" value="1" />
                      <Tab label="Feuerwehr Einstellungen" value="2" disabled />
                      <Tab label="Feuerwehr Verwalten" value="3" disabled />
                    </TabList>
                  )}
                </Grid>
              </Toolbar>
            </AppBar>
          </Grid>
          <TabPanel value="1">
            {member && <Member />}
            {device && <Device />}
          </TabPanel>
          <TabPanel value="2">
            <FireDepartmentView />
          </TabPanel>
          <TabPanel value="3">
            <FireDepartmentManageView />
          </TabPanel>
          <TabPanel value="4">
            <div></div>
          </TabPanel>
        </TabContext>
      </Grid>
    )
  );

  // return (
  //   <Box sx={{ height: "100vh" }}>
  //     <Paper elevation={3} sx={{ padding: 2 }}>
  //       <Typography variant="h5" gutterBottom>
  //         Profilansicht
  //       </Typography>
  //       <Grid container spacing={2}>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Name:</Typography>
  //           <Typography>{user.name}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Vorname:</Typography>
  //           <Typography>{user.given_name}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Nachname:</Typography>
  //           <Typography>{user.family_name}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Spitzname:</Typography>
  //           <Typography>{user.nickname}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">E-Mail:</Typography>
  //           <Typography>{user.email}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Geschlecht:</Typography>
  //           <Typography>{user.gender}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Geburtsdatum:</Typography>
  //           <Typography>{user.birthdate}</Typography>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <Typography variant="subtitle1">Telefonnummer:</Typography>
  //           <Typography>{user.phone_number}</Typography>
  //         </Grid>
  //         {/* Weitere Felder hier hinzufügen */}
  //       </Grid>
  //     </Paper>
  //   </Box>
  // );
};

export default ProfileView;

import { IMetaData } from "./../common/types";
import { v4 as uuidv4 } from "uuid";
import { IModelbase } from "./modelbase";
import { SectionType } from "./modeltypes";

export interface IDocumentation extends IModelbase {
  missionNumber: string;
  missionKeyword: string;
  startDate: string;
  note?: string;
  modules: IModules;
  provisionAreas?: IMetaData;
  address?: IAddress;
}

export interface IAddress {
  street?: string;
  houseNumber?: string;
  district?: string;
  city?: string;
}

export interface IModules {
  vehicleManager: boolean;
  mesure: boolean;
  section: SectionType;
}

export interface IProvisionArea extends IModelbase {
  name: string;
  address?: IAddress;
}

export interface ITemplateCard {
  headline: string;
  description: string;
  linkName: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  img?: string;
  selection?: React.ReactNode;
}

export const newDocumentation = (keyword?: string, vehicleManager?: boolean, mesure?: boolean) => {
  return {
    id: uuidv4(),
    startDate: new Date().toLocaleString(),
    missionKeyword: keyword,
    missionNumber: "",
    modules: { vehicleManager: vehicleManager, mesure: mesure, section: "Single" } as IModules,
  } as IDocumentation;
};

export const newAddress = () => {
  return {
    street: "",
    houseNumber: "",
    district: "",
    city: "",
  } as IAddress;
};

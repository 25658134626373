import { useStores } from "../../stores/stores";

const useMesurementList = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const mesurementsList = useStores("documentation", (data) =>
    data.mesurements.filter((d) => d.documentationId == selectedDocumentationId)
  );

  return { mesurementsList };
};

export default useMesurementList;

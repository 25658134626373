import React from "react";
import { Toolbar as MuiToolbar } from "@mui/material";
import { IToolbar } from "./types";

const Toolbar = React.forwardRef<HTMLDivElement, IToolbar>(({ children, ...props }, ref) => {
  return (
    <MuiToolbar ref={ref} {...props}>
      {children}
    </MuiToolbar>
  );
});

export default Toolbar;

import React, { useState } from "react";
import UnitCard from "./UnitCard";
import UnitEditPart from "./UnitEditPart";
import { stores, useStores } from "../../stores/stores";
import useUnit from "../hooks/useUnit";
import useFeedback from "../../feedback/hooks/useFeedback";
import Grid from "../../common/components/atomics/Grid";
import Typography from "../../common/components/atomics/Typography";
import { IUnit } from "../../model/unit";
import { newAddress } from "../../model/documentation";

const UnitListItemCard = (props: any) => {
  const unit = props.unit as IUnit;
  const { deleteUnit } = useUnit();
  const { addFeedback } = useFeedback();
  const [openAddress, setOpenAddress] = useState(false);
  const selectedUnitId = useStores("cache", (data) => data.selectedUnitId);
  const changeDocumentation = stores.documentation.getState().update;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeDocumentation((data: any) => {
      switch (e.target.name) {
        case "name":
          data.crews.find((d: IUnit) => d.id == unit.id)!.name = e.target.value;
          break;
        case "crewType":
          data.crews.find((d: IUnit) => d.id == unit.id)!.crewType = e.target.value;
          break;
        case "note":
          data.crews.find((d: IUnit) => d.id == unit.id)!.note = e.target.value;
          break;
      }
    });
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeDocumentation((data: any) => {
      data.crews.find((d: IUnit) => d.id == unit.id)!.address[e.target.name] = e.target.value;
    });
  };

  const handleDelete = (item: IUnit) => {
    addFeedback("Person mit Name '" + item.name + "' wurde gelöscht.", "extended", true);
    deleteUnit(item);
  };

  const handleAddAddress = () => {
    changeDocumentation((data: any) => {
      data.crews.find((d: IUnit) => d.id == unit.id)!.address = newAddress();
    });
    setOpenAddress(true);
  };

  return (
    <UnitCard
      deleteable
      handleDelete={handleDelete}
      unit={unit}
      mainContent={
        <Grid container item spacing={2} rowSpacing={2} m={1}>
          <Typography>{unit.note}</Typography>
        </Grid>
      }
      expandContent={
        <Grid item container spacing={2} rowSpacing={2}>
          <UnitEditPart
            unit={unit}
            handleChange={handleChange}
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            handleChangeAddress={handleChangeAddress}
            handleAddAddress={handleAddAddress}
          />
        </Grid>
      }
    />
  );
};

export default UnitListItemCard;

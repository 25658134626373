import React, { useState } from "react";
import Box from "../common/components/atomics/Box";
import Paper from "../common/components/atomics/Paper";
import Typography from "../common/components/atomics/Typography";
import Grid from "../common/components/atomics/Grid";
import { stores, useStores } from "../stores/stores";
import TextField from "../common/components/atomics/TextField";
import { IMember } from "../model/member";
import Button from "../common/components/atomics/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IDevice } from "../model/device";
import { StoresDataType } from "../stores/storeTypes";

const Device = (props: any) => {
  const selectedDeviceId = useStores("ui", (data) => data.selectedDeviceId);
  const changeUi = stores.ui.getState().update;
  const fd = useStores("remote", (data) => data.fireDepartment);
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!fd) {
    return <div>Loading devices...</div>;
  }
  const handleChange = (e: SelectChangeEvent<string>) => {
    changeUi((data: StoresDataType["ui"]) => {
      data.selectedDeviceId = e.target.value;
    });
  };

  const handleSave = () => {};

  return (
    <Box sx={{ height: "100vh" }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          Profileinstellungen
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Verfügbare Geräte</InputLabel>
              <Select
                value={selectedDeviceId !== "" ? selectedDeviceId : fd.devices[0].id}
                label="Verfügbare Geräte"
                onChange={(e) => handleChange(e)}
              >
                {fd &&
                  fd.devices.map((d: IDevice) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            {/* <Button variant="outlined" onClick={handleSave}>
              Aktualisieren
            </Button> */}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Device;

import { newFeedback, newSnackMessage } from "../../model/feedback";
import { FeedbackPriority } from "../../model/modeltypes";
import { stores, useStores } from "../../stores/stores";

const useFeedback = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const changeSnack = stores.ui.getState().update;
  const changeDocumentation = stores.documentation.getState().update;

  const addFeedback = (
    message: string,
    priority: FeedbackPriority,
    showSnackbar: boolean,
    docuId?: string | undefined,
    vehicleBos?: string | undefined
  ) => {
    changeDocumentation((data) => {
      data.feedbacks.push(newFeedback(docuId ? docuId : selectedDocumentationId, message, priority, vehicleBos));
    });
    if (showSnackbar)
      changeSnack((data) => {
        data.snackPack = [...data.snackPack, newSnackMessage(message)];
      });
  };

  const showSnackbar = (message: string) => {
    changeSnack((data) => {
      data.snackPack = [...data.snackPack, newSnackMessage(message)];
    });
  };
  return { addFeedback, showSnackbar };
};

export default useFeedback;

import React from "react";
import { Button as MuiButton } from "@mui/material";
import { IButton } from "./types";

const Button: React.FC<IButton> = (props) => {
  return (
    <MuiButton
      variant={props.variant}
      disabled={props.disabled}
      onClick={props.onClick}
      color={props.color}
      size={props.size}
      style={props.style}
      sx={props.sx}
      fullWidth
    >
      {props.children}
    </MuiButton>
  );
};

export default Button;

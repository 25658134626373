import React, { useState } from "react";
import Box from "../common/components/atomics/Box";
import Paper from "../common/components/atomics/Paper";
import Typography from "../common/components/atomics/Typography";
import Grid from "../common/components/atomics/Grid";
import { stores, useStores } from "../stores/stores";
import TextField from "../common/components/atomics/TextField";
import { IMember } from "../model/member";
import Button from "../common/components/atomics/Button";
import { useAuth0 } from "@auth0/auth0-react";

const Member = (props: any) => {
  const member = useStores("cache", (data) => data.member);
  const changeMember = stores.cache.getState().update;
  const [localMember, setLocalMember] = useState(member);
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!user) return <div>no user ...</div>;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeMember((data: any) => {
      data.member[e.target.name] = e.target.value;
    });
  };

  const handleSave = () => {};

  if (!member) return <Typography>Es wurde kein Einsatzdoku User gefunden.</Typography>;

  return (
    <Box sx={{ height: "100vh" }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          Memberprofil
        </Typography>
        <Box>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="firstname"
              label="Vorname"
              value={member.firstname}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastname"
              label="Nachname"
              value={member.lastname}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="displayname"
              label="Anzeigename"
              value={member.displayName}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">E-Mail:</Typography>
            <Typography>{member.email}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleSave}>
              Aktualisieren
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Member;

import axios from "axios";
import settingsData from "../demptool.settings.json";
import { useStores } from "./stores";

export class ApiCall<T> {
  headers: any;
  url: string;
  constructor(route: string, token: string) {
    this.headers = { Authorization: "Bearer " + token };
    if (route === "apiStaticRoute") {
      this.url = settingsData.apiCommunication.apiStaticRoute;
    } else if (route === "apiAppRoute") {
      this.url = settingsData.apiCommunication.apiAppRoute;
    } else if (route === "apiManagementRoute") {
      this.url = settingsData.apiCommunication.apiManagementRoute;
    } else this.url = "";
  }

  async update(item: any, path = ""): Promise<T | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
        method: "patch",
        data: item,
      });
      return response.data as T;
    } catch (err: any) {
      console.log("API-Error: ", err.message);
      return undefined;
    }
  }

  async post(item: any, path = ""): Promise<T | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
        method: "post",
        data: item,
      });
      return response.data as T;
    } catch (err: any) {
      console.log("API-Error: ", err);
      return undefined;
    }
  }

  async delete(item: any, path = ""): Promise<boolean> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
        method: "delete",
        data: item,
      });
      return response.data;
    } catch (err: any) {
      return err.message;
    }
  }

  async call(path = ""): Promise<T | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
      });

      return response.data as T;
    } catch (err: any) {
      console.log("API-Error: ", err.message);
      return undefined;
    }
  }

  async get(id: string, path = ""): Promise<T | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path + "/" + id,
      });

      return response.data as T;
    } catch (err: any) {
      console.log("API-Error: ", err.message);
      return undefined;
    }
  }

  async getList(id: string, path = ""): Promise<T[] | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
        data: id,
      });

      return response.data as T[];
    } catch (err: any) {
      console.log("API-Error: ", err.message);
      return undefined;
    }
  }

  async getAll(path = ""): Promise<T[] | undefined> {
    try {
      const response = await axios.request({
        headers: this.headers,
        baseURL: settingsData.apiCommunication.apiHostUrl,
        url: this.url + path,
      });
      return response.data as T[];
    } catch (err: any) {
      console.log("API-Error: ", err.message);
      return undefined;
    }
  }
}

import { Collapse, Select, MenuItem, Autocomplete, SelectChangeEvent } from "@mui/material";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import React, { useState } from "react";
import CrewList from "./CrewList";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "../../common/components/atomics/IconButton";
import Grid from "../../common/components/atomics/Grid";
import Card from "../../common/components/atomics/Card";
import CardHeader from "../../common/components/atomics/CardHeader";
import Avatar from "../../common/components/atomics/Avatar";
import TextField from "../../common/components/atomics/TextField";
import CardContent from "../../common/components/atomics/CardContent";
import ListItem from "../../common/components/atomics/ListItem";
import Tooltip from "../../common/components/atomics/Tooltip";
import Divider from "../../common/components/atomics/Divider";
import { IVehicle } from "../../model/vehicle";
import { OrganisationTypes } from "../../model/modeltypes";
import { IUnit } from "../../model/unit";
import { useStores } from "../../stores/stores";

const VehicleCard = (props: any) => {
  const { item, handleAddCrew, handleArrived, crew, changeVehicle } = props;

  const [openVehicle, setOpenVehicle] = useState(false);

  const radioNames = useStores("remote", (data) => data.radioNames);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeVehicle((data: any) => {
      data.vehicles.find((d: IVehicle) => d.id == item.id)![e.target.name] = e.target.value;
    });
  };

  // const handleChangeType = (e: SelectChangeEvent<any>) => {
  //   console.log("jetz change");
  //   let value = undefined as any;
  //   switch (e.target.value) {
  //     case "Feuerwehr":
  //       value = {
  //         type: "Feuerwehr",
  //         crewTypes: crewTypes["Feuerwehr"],
  //       };
  //       break;
  //     case "Rettungsdienst":
  //       value = {
  //         type: "Rettungsdienst",
  //         crewTypes: crewTypes["Rettungsdienst"],
  //       };
  //       break;
  //     case "Polizei":
  //       value = {
  //         type: "Polizei",
  //         crewTypes: crewTypes["Polizei"],
  //       };
  //       break;
  //     case "Sonstige":
  //       value = {
  //         type: "Sonstige",
  //         crewTypes: crewTypes["Sonstige"],
  //       };
  //       break;
  //   }
  //   changeVehicle((data: any) => {
  //     data.vehicles.find((d: IVehicle) => d.id == item.id)![e.target.name] = value;
  //   });
  // };

  const handleDelete = (item: IVehicle) => {
    changeVehicle((data: any) => {
      data.vehicles = data.vehicles.filter((d: IVehicle) => d.id != item.id);
      data.crews = data.crews.filter((d: IUnit) => d.vehicleId != item.id);
    });
  };

  return (
    <Grid item m={1}>
      <Card sx={{ minWidth: 300, maxWidth: 445 }}>
        <CardHeader
          avatar={
            <Avatar color="primary">
              {item.vehicleType === OrganisationTypes.Feuerwehr && (
                <FireTruckIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Rettungsdienst && (
                <LocalHospitalIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Polizei && (
                <LocalPoliceIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Sonstige && (
                <TimeToLeaveIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
            </Avatar>
          }
          title={item.bos ? item.bos : item.vehicleName}
          subheader={OrganisationTypes[item.vehicleType]}
          action={
            <IconButton onClick={() => setOpenVehicle((prev) => !prev)}>
              {openVehicle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <Collapse in={openVehicle} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  disabled={item.isRemoved}
                  name="vehicleName"
                  label="Bezeichnung"
                  size="small"
                  value={item.vehicleName}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <Autocomplete
                  freeSolo
                  disabled={item.isRemoved}
                  // name="bos"
                  size="small"
                  options={radioNames}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option === value}
                  value={item.bos}
                  renderInput={(params) => <TextField {...params} label="Funkrufname" placeholder="Funkrufname" />}
                  onChange={(e, newValue) =>
                    changeVehicle((data: any) => {
                      data.vehicles.find((d: IVehicle) => d.id === item.id).bos = newValue;
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <Select
                  disabled={crew.length > 0 || item.isRemoved}
                  name="vehicleType"
                  size="small"
                  value={item.vehicleType}
                  onChange={(e: any) => handleChange(e)}
                >
                  <MenuItem value={OrganisationTypes.Feuerwehr}>Feuerwehr</MenuItem>
                  <MenuItem value={OrganisationTypes.Rettungsdienst}>Rettungsdienst</MenuItem>
                  <MenuItem value={OrganisationTypes.Polizei}>Polizei</MenuItem>
                  <MenuItem value={OrganisationTypes.Sonstige}>Sonstige</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <CrewList
                  disabled={item.isRemoved}
                  crewsList={crew}
                  vehicleType={item.vehicleType}
                  changeCrew={changeVehicle}
                />
              </Grid>
              <ListItem
                secondaryAction={
                  <Grid container>
                    {handleArrived && (
                      <Grid item>
                        <Tooltip title={item.isArrived ? "Fahrzeug abziehen" : "Fahrzeug angekommen"}>
                          <div>
                            <IconButton disabled={item.isRemoved} onClick={() => handleArrived(item)}>
                              {item.isArrived ? <FlightTakeoffIcon /> : <FlightLandIcon />}
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      <IconButton disabled={item.vehicleName !== ""} onClick={() => handleDelete(item)}>
                        <Tooltip
                          title={
                            item.vehicleName !== ""
                              ? "Zum Fahrzeug löschen bitte Fahrzeugbezeichnung entfernen"
                              : "Fahrzeug und Mannschaft löschen"
                          }
                        >
                          <div>
                            <DeleteIcon />
                          </div>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              >
                <Tooltip title="Neue Einsatzkraft dem Fahrzeug hinzufügen">
                  <div>
                    <IconButton disabled={!item.isArrived || item.isRemoved} onClick={() => handleAddCrew(item)}>
                      <PersonAddIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </ListItem>
            </Grid>
          </CardContent>
        </Collapse>
        <Divider />
      </Card>
    </Grid>
  );
};

export default VehicleCard;

import React from "react";
import useDocumentationList from "../hooks/useDocumentationList";
import DocumentationListItem from "./DocumentationListItem";
import { useNavigate } from "react-router-dom";
import { stores } from "../../stores/stores";
import Grid from "../../common/components/atomics/Grid";
import Typography from "../../common/components/atomics/Typography";
import Paper from "../../common/components/atomics/Paper";
import List from "../../common/components/atomics/List";
import Box from "../../common/components/atomics/Box";

const DocumentationList = () => {
  const navigate = useNavigate();
  const { documentationList } = useDocumentationList();

  const changeSelectedDocumentation = stores.ui.getState().update;

  const handleClick = (id: string) => {
    changeSelectedDocumentation((data) => {
      data.selectedDocumentationId = id;
    });
    changeSelectedDocumentation((data) => {
      data.selectedMesureId = "";
    });
    navigate("/docu");
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Grid container rowSpacing={2} spacing={2}>
        <Grid item xs={12}>
          <Typography>Einsatzdokumentationen</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <List>
              {documentationList &&
                documentationList.map((item, i) => (
                  <DocumentationListItem key={item.id} number={i + 1} item={item} onClick={handleClick} />
                ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentationList;

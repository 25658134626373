// export type MesureDeviceType = {
//   id: string;
//   name: string;
//   description?: string;
//   sensors: SensorType[];
// };

// export type SensorType = {
//   id: string;
//   sensorName: string;
//   description?: string;
//   unit: string;
// };

export type UnitType = "crew" | "client";

// export type VehicleType = {
//   type: string;
//   crewTypes: string[];
// };

export type CrewTypeName = {
  orgaType: OrganisationTypes;
  crewNames: string[];
};

export type FeedbackPriority = "normal" | "extended";

export type SectionType = "Single" | "Master" | "Slave";

export enum OrganisationTypes {
  "Feuerwehr",
  "Rettungsdienst",
  "Polizei",
  "Sonstige",
}

//export const BosNames = ["1/11-1", "1/24-1", "1/34-1", "1/46-1", "1/53-1", "2/11-1", "2/34-1", "2/46-1"];
// export const crewTypes = {
//   Feuerwehr: [
//     "Einsatzleiter",
//     "Fahrzeugführer",
//     "Maschinist",
//     "Angriffstrupp",
//     "Wassertrupp",
//     "Schlauchtrupp",
//     "Abschnittsleiter",
//     "Führungsgehilfe",
//   ],
//   Rettungsdienst: ["Not-San", "Notarzt"],
//   Polizei: ["Polizist"],
//   Sonstige: ["Zeuge", "Betroffener", "Sonstiger"],
// };

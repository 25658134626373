import { Typography, Grid, Divider, ListItemIcon, ListItem, ListItemText, Avatar, Tooltip } from "@mui/material";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import AddCommentIcon from "@mui/icons-material/AddComment";

import React from "react";
import { stores, useStores } from "../../stores/stores";
import IconButton from "../../common/components/atomics/IconButton";
import { OrganisationTypes } from "../../model/modeltypes";

const VehicleListItem = (props: any) => {
  const { item, handleAddVehicleFeedback, handleArrived, crew } = props;
  const changeVehicle = stores.documentation.getState().update;
  const vehicleFeedback = useStores("ui", (data) => data.vehicleFeedback);

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12}>
        <ListItem
          selected={vehicleFeedback && vehicleFeedback.id === item.id}
          secondaryAction={
            handleArrived && !item.isArrived ? (
              <IconButton onClick={() => handleArrived(item)}>{<FlightLandIcon />}</IconButton>
            ) : (
              item.bos && (
                <Tooltip title="Fahrzeugbezogene Rückmeldung verfassen">
                  <IconButton onClick={() => handleAddVehicleFeedback(item)}>
                    <AddCommentIcon />
                  </IconButton>
                </Tooltip>
              )
            )
          }
        >
          <ListItemIcon>
            <Avatar color="primary">
              {item.vehicleType === OrganisationTypes.Feuerwehr && (
                <FireTruckIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Rettungsdienst && (
                <LocalHospitalIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Polizei && (
                <LocalPoliceIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
              {item.vehicleType === OrganisationTypes.Sonstige && (
                <TimeToLeaveIcon color={item.isArrived ? (item.isRemoved ? "error" : "success") : "inherit"} />
              )}
            </Avatar>
          </ListItemIcon>
          <Grid item xs={7}>
            <Grid item xs={12}>
              <Typography>{item.vehicleName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{OrganisationTypes[item.vehicleType]}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} textAlign="left">
            <Typography>{item.bos}</Typography>
          </Grid>
          <Grid item xs={1}>
            {item.vehicleType === OrganisationTypes.Feuerwehr && (
              <ListItemText primary={crew.length} secondary="Besatzung"></ListItemText>
            )}
          </Grid>
        </ListItem>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default VehicleListItem;

import React, { useState } from "react";
import ListItem from "../common/components/atomics/ListItem";
import ListItemAvatar from "../common/components/atomics/ListItemAvatar";
import ListItemText from "../common/components/atomics/ListItemText";
import Typography from "../common/components/atomics/Typography";
import Badge from "../common/components/atomics/Badge";
import IconButton from "../common/components/atomics/IconButton";
import { ModeEdit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DeviceListItem = (props: any) => {
  const { item, number } = props;
  let navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate("/account/device/" + id);
  };
  return (
    <ListItem
      sx={{ height: 50 }}
      secondaryAction={
        <IconButton aria-label="edit" onClick={() => handleEdit(item.id)}>
          <ModeEdit />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Badge badgeContent={number} color="primary" />
      </ListItemAvatar>
      <ListItemText primary={item.name} />
    </ListItem>
  );
};

export default DeviceListItem;

import React from "react";
import Grid from "../common/components/atomics/Grid";

import Paper from "../common/components/atomics/Paper";
import Typography from "../common/components/atomics/Typography";
import List from "../common/components/atomics/List";
import Divider from "../common/components/atomics/Divider";
import { useNavigate } from "react-router-dom";
import ListItem from "../common/components/atomics/ListItem";
import IconButton from "../common/components/atomics/IconButton";
import ListItemAvatar from "../common/components/atomics/ListItemAvatar";
import { Badge } from "@mui/material";
import ListItemText from "../common/components/atomics/ListItemText";
import UnsubscribeSharpIcon from "@mui/icons-material/UnsubscribeSharp";

const InviteList = (props: any) => {
  const { invitesList, handleEdit } = props;

  let navigate = useNavigate();

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            m={1}
            fontSize={20}
            style={{
              textAlign: "center",
            }}
          >
            Einladungsliste
          </Typography>
          <Divider style={{ marginBottom: "20px" }} />
        </Grid>
        <Grid item xs={12}>
          <List>
            {invitesList.map((item: string, i: any) => (
              <ListItem
                key={item + i}
                sx={{ height: 50 }}
                secondaryAction={
                  <IconButton aria-label="remove">
                    <UnsubscribeSharpIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Badge badgeContent={1 + i} color="primary" />
                </ListItemAvatar>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </Paper>
  );
};

export default InviteList;

import React from "react";
import useVehicle from "../hooks/useVehicle";
import Button from "../../common/components/atomics/Button";
import Grid from "../../common/components/atomics/Grid";
import Paper from "../../common/components/atomics/Paper";

const AddVehicle = (props: any) => {
  const {
    isWriting,
    setIsWriting,
    handleAddVehicle,
    handleRequireVehicle,
    buttonLabel,
    disabled,
    insertId,
    templateMode,
  } = props;
  const { vehicleTemplates, shiftTemplates } = useVehicle(insertId);

  return (
    <React.Fragment>
      {isWriting ? (
        <Grid container rowSpacing={2} spacing={2} m={1}>
          <Grid item xs={12}>
            {!templateMode && "Spezifisch erstellte Fahrzeuge"}
          </Grid>
          {!templateMode &&
            shiftTemplates.vehicles.map((v) => (
              <Grid key={v.id} item xs={3} mt={2}>
                <Paper elevation={2}>
                  <Button onClick={() => handleAddVehicle(v)}>
                    {v.vehicleName} {"("}
                    {v.bos}
                    {")"}
                  </Button>
                </Paper>
              </Grid>
            ))}
          <Grid item xs={12}>
            Allgemeine Vorlagen
          </Grid>
          {vehicleTemplates.map((v) => (
            <Grid key={v.vehicleName} item xs={3} mt={2}>
              <Paper elevation={2}>
                <Button onClick={() => handleAddVehicle(v.vehicle)}>{v.vehicleName}</Button>
              </Paper>
            </Grid>
          ))}

          <Grid key="back" item xs={3} mt={2}>
            <Paper elevation={2}>
              <Button onClick={() => setIsWriting(false)}>Zurück</Button>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} mt={2}>
          <Button disabled={disabled} variant="contained" onClick={handleRequireVehicle}>
            {buttonLabel}
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default AddVehicle;

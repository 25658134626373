import { FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { stores, useStores } from "../../stores/stores";
import useFeedback from "../hooks/useFeedback";
import useFeedbackList from "./../hooks/useFeedbackList";
import FeedbackList from "./FeedbackList";
import AddFeedback from "./AddFeedback";
import Grid from "../../common/components/atomics/Grid";
import Typography from "../../common/components/atomics/Typography";
import Paper from "../../common/components/atomics/Paper";
import Button from "../../common/components/atomics/Button";
import { IFeedback } from "../../model/feedback";
import { OrganisationTypes } from "../../model/modeltypes";

const Feedbacks = () => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const [showExtendFeedback, setShowExtendFeedback] = useState(false);
  const { feedbacksList } = useFeedbackList();
  const { addFeedback } = useFeedback();
  const vehicleFeedback = useStores("ui", (data) => data.vehicleFeedback);

  const changeUi = stores.ui.getState().update;
  const { showSnackbar } = useFeedback();

  useEffect(() => {
    if (vehicleFeedback) {
      setIsWriting(true);
      showSnackbar(
        "Fahrzeugbezogene Rückmeldung auf " + vehicleFeedback.bos + " Typ " + OrganisationTypes[vehicleFeedback.vehicleType] + " gesetzt."
      );
    }
  }, [vehicleFeedback]);

  const handleAddFeedback = () => {
    addFeedback(feedback, "normal", false, undefined, vehicleFeedback ? vehicleFeedback.bos : undefined);
    setIsWriting(false);
    setFeedback("");
    changeUi((data) => {
      data.vehicleFeedback = undefined;
    });
    setDisableSubmit(true);
  };

  const handleChangeFeedback = (e: React.ChangeEvent<HTMLButtonElement>) => {
    setFeedback(e.target.value);
    setDisableSubmit(e.target.value == undefined || e.target.value == "" ? true : false);
  };

  const handleCreateFeedback = () => {
    setIsWriting(true);
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowExtendFeedback(event.target.checked);
  };

  return (
    <Grid sx={{ p: 1 }} container spacing={2} rowSpacing={2}>
      <Grid item xs={6}>
        <Typography>Rückmeldungen</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          label="erweiterte Meldungen anzeigen"
          control={<Switch onChange={handleSwitch} inputProps={{ "aria-label": "controlled" }} />}
        ></FormControlLabel>
      </Grid>

      <Grid item xs={12}>
        <Paper variant="outlined">
          <Grid item container xs={12} spacing={2} rowSpacing={2}>
            {isWriting ? (
              <Grid item xs={12} m={2}>
                {/* {vehicleFeedback && (
                  <Grid item xs={12} m={1}>
                    <Typography>
                      Rückmeldung Fahrzeugbezogen auf {vehicleFeedback.bos} Typ {vehicleFeedback.vehicleType.type}
                    </Typography>
                  </Grid>
                )} */}
                <Grid item xs={12}>
                  <AddFeedback
                    feedback={feedback}
                    handleChange={handleChangeFeedback}
                    handleAddFeedback={handleAddFeedback}
                    disableSubmit={disableSubmit}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (vehicleFeedback) {
                        changeUi((data) => {
                          data.vehicleFeedback = undefined;
                        });

                        showSnackbar("Fahrzeugbezogene Rückmeldung zurückgesetzt.");
                      }
                      setIsWriting(false);
                    }}
                  >
                    Zurück
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} m={2}>
                <Button variant="contained" onClick={handleCreateFeedback}>
                  Rückmeldung verfassen
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <FeedbackList
              feedbacksList={
                showExtendFeedback
                  ? feedbacksList.filter((d: IFeedback) => d.priority == "normal" || d.priority == "extended")
                  : feedbacksList.filter((d: IFeedback) => d.priority == "normal")
              }
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Feedbacks;

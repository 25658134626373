import React from "react";
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from "@mui/material";
import { IIconButton } from "./types";

const IconButton = React.forwardRef<HTMLButtonElement, IIconButton>(({ children, ...props }, ref) => {
  return (
    <MuiIconButton ref={ref} {...props}>
      {children}
    </MuiIconButton>
  );
});

export default IconButton;

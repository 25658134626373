import React from "react";
import Button from "../../common/components/atomics/Button";
import Grid from "../../common/components/atomics/Grid";
import Paper from "../../common/components/atomics/Paper";
import Card from "../../common/components/atomics/Card";
import CardContent from "../../common/components/atomics/CardContent";
import Typography from "../../common/components/atomics/Typography";
import Box from "../../common/components/atomics/Box";
import CardActions from "../../common/components/atomics/CardActions";
import { ITemplateCard } from "../../model/documentation";

const DocuTemplateCard: React.FC<ITemplateCard> = ({
  headline,
  description,
  linkName,
  handleClick,
  img,
  selection,
}) => {
  return (
    <Grid item xs={5}>
      <Paper elevation={3}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {headline}
            </Typography>
            <Typography mb={2} variant="body2">
              {description}
            </Typography>
            <Box m={2}>{selection}</Box>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={handleClick}>
              {linkName}
            </Button>
          </CardActions>
        </Card>
      </Paper>
    </Grid>
  );
};

export default DocuTemplateCard;

import { Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { stores, useStores } from "../../stores/stores";
import useVehicle from "./../hooks/useVehicle";
import VehicleList from "./VehicleList";
import useFeedback from "../../feedback/hooks/useFeedback";
import useUnit from "../../unit/hooks/useUnit";
import { IVehicle } from "../../model/vehicle";

const Vehicles = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const [vehicle, setVehicle] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const { vehiclesList, unitsList } = useVehicle();
  const { addFeedback } = useFeedback();
  const { addUnit } = useUnit();

  const changeVehicle = stores.documentation.getState().update;
  const changeUi = stores.ui.getState().update;
  const changeCrew = stores.documentation.getState().update;

  const handleAddVehicle = (vehicle: IVehicle) => {
    changeVehicle((data) => {
      data.vehicles.push(vehicle);
    });
    addFeedback("Fahrzeug '" + vehicle.vehicleName + "' wurde angefordert.", "extended", true);

    setIsWriting(false);
    setVehicle("");
  };

  const handleArrived = (vehicle: IVehicle) => {
    changeVehicle((data) => {
      data.vehicles.find((d) => d.id == vehicle.id)!.isArrived = true;
    });

    addFeedback("Fahrzeug '" + vehicle.vehicleName + "' ist am Einsatzort eingetroffen.", "extended", true);
  };

  const handleAddCrew = (vehicle: IVehicle) => {
    addUnit(selectedDocumentationId, "crew", vehicle.id);

    addFeedback("Neue Einsatzkraft dem Fahrzeug '" + vehicle.vehicleName + "' hinzugefügt", "extended", false);
  };

  const handleAddVehicleFeedback = (vehicle: IVehicle) => {
    changeUi((data) => {
      data.vehicleFeedback = vehicle;
    });
  };

  return (
    <Grid sx={{ p: 1 }} container spacing={2} rowSpacing={2}>
      <Grid item xs={12}>
        <Typography>Fahrzeuge</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <VehicleList
              vehiclesList={vehiclesList}
              crewsList={unitsList}
              handleAddCrew={handleAddCrew}
              handleArrived={handleArrived}
              handleAddVehicleFeedback={handleAddVehicleFeedback}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Vehicles;

import React, { useState } from "react";
import { stores, useStores } from "../../stores/stores";
import MesurementList from "./MesurementList";
import useMesurementList from "../hooks/useMesurementList";
import useMesurement from "./../hooks/useMesurement";
import MesureDetail from "./MesureDetail";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useFeedback from "../../feedback/hooks/useFeedback";
import IconButton from "../../common/components/atomics/IconButton";
import Paper from "../../common/components/atomics/Paper";
import Grid from "../../common/components/atomics/Grid";
import ListItem from "../../common/components/atomics/ListItem";
import Tooltip from "../../common/components/atomics/Tooltip";
import Divider from "../../common/components/atomics/Divider";
import Typography from "../../common/components/atomics/Typography";
import Button from "../../common/components/atomics/Button";
import { newMesurement } from "../../model/mesurement";
import { IMesureDevice } from "../../model/templateMesureDevice";

const MesureView = () => {
  const [isWriting, setIsWriting] = useState(false);
  const { mesurementsList } = useMesurementList();
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const selectedMesurementId = useStores("ui", (data) => data.selectedMesureId);
  const { mesureDeviceTemplates } = useMesurement();
  const { addFeedback } = useFeedback();

  const changeDocumentation = stores.documentation.getState().update;
  const changeSelectedMesurementId = stores.ui.getState().update;

  const handleMesurePrint = () => {
    window.open("/docu/detail/mesure/print");
  };

  const handleClick = (id: string) => {
    changeSelectedMesurementId((data) => {
      data.selectedMesureId = id;
    });
  };

  const handleAddMesure = (mesureDevice: IMesureDevice) => {
    changeDocumentation((data) => {
      data.mesurements.push(newMesurement(selectedDocumentationId, mesureDevice));
    });
    addFeedback("Messgerät '" + mesureDevice.name + "' wurde ausgegeben.", "extended", true);

    setIsWriting(false);
  };

  const handleRequireMesure = () => {
    setIsWriting(true);
  };

  return (
    <Paper>
      <Grid pb={1} pl={1} pr={1} container spacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <ListItem
            secondaryAction={
              <div>
                <Tooltip title="Messprotokoll Druckansicht" onClick={handleMesurePrint}>
                  <IconButton>
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
          >
            <Typography>Messdokumentation</Typography>
          </ListItem>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Paper variant="outlined">
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>

            <MesurementList mesurementList={mesurementsList} onClick={handleClick} />

            <Grid item xs={12}>
              <Grid item container xs={12} spacing={2} rowSpacing={2}>
                {isWriting ? (
                  mesureDeviceTemplates
                    .map((v) => (
                      <Grid key={v.name} item xs={3} mt={2}>
                        <Paper elevation={2}>
                          <Button onClick={() => handleAddMesure(v)}>{v.name}</Button>
                        </Paper>
                      </Grid>
                    ))
                    .concat(
                      <Grid key="back" item xs={3} mt={2}>
                        <Paper elevation={2}>
                          <Button onClick={() => setIsWriting(false)}>Zurück</Button>
                        </Paper>
                      </Grid>
                    )
                ) : (
                  <Grid item xs={12} mt={2}>
                    <Button variant="contained" onClick={handleRequireMesure}>
                      Messgerät ausgeben
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper variant="outlined">{selectedMesurementId && <MesureDetail />}</Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MesureView;

import React from "react";
import { Tab as MuiTab } from "@mui/material";
import { ITab } from "./types";

const Tab = React.forwardRef<HTMLDivElement, ITab>(({ children, ...props }, ref) => {
  return (
    <MuiTab ref={ref} {...props}>
      {children}
    </MuiTab>
  );
});

export default Tab;

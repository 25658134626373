import React from "react";
import { Stack as MuiStack } from "@mui/material";
import { IStack } from "./types";

const Stack = React.forwardRef<HTMLDivElement, IStack>(({ children, ...props }, ref) => {
  return (
    <MuiStack ref={ref} {...props}>
      {children}
    </MuiStack>
  );
});

export default Stack;

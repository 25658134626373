import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import ListItem from "../../common/components/atomics/ListItem";
import ListItemAvatar from "../../common/components/atomics/ListItemAvatar";
import Badge from "../../common/components/atomics/Badge";
import ListItemText from "../../common/components/atomics/ListItemText";
import IconButton from "../../common/components/atomics/IconButton";

const DocumentationListItem = (props: any) => {
  const { item, onClick, number } = props;
  return (
    <ListItem dense>
      <ListItemAvatar>
        <Badge badgeContent={number} color="primary" />
      </ListItemAvatar>
      <ListItemText primary={item.missionNumber} secondary="Einsatznummer" />
      <ListItemText primary={item.startDate} secondary="Einsatzdatum" />
      <IconButton onClick={() => onClick(item.id)}>
        <EditIcon />
      </IconButton>
    </ListItem>
  );
};

export default DocumentationListItem;

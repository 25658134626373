import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { Collapse, ListItemIcon, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Address from "../../documentation/components/Address";
import Button from "../../common/components/atomics/Button";
import Grid from "../../common/components/atomics/Grid";
import TextField from "../../common/components/atomics/TextField";
import ListItemButton from "../../common/components/atomics/ListItemButton";
import ListItemText from "../../common/components/atomics/ListItemText";
import { IUnit } from "../../model/unit";
import { useStores } from "../../stores/stores";
import { OrganisationTypes } from "../../model/modeltypes";

const UnitEditPart = (props: any) => {
  const { handleChange, openAddress, setOpenAddress, handleChangeAddress, handleAddAddress } = props;
  const unit = props.unit as IUnit;

  const crewTypes = useStores("remote", (data) => data.crewTypes);

  return (
    <Grid container item spacing={2} rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          size="small"
          name="name"
          label="Name der Person"
          value={unit.name}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {unit.address ? (
          <Grid>
            <ListItemButton onClick={() => setOpenAddress(!openAddress)}>
              <ListItemText primary="Adressdaten" />
              <ListItemIcon>{openAddress ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            </ListItemButton>
            <Grid m={2}>
              <Collapse in={openAddress} timeout="auto" unmountOnExit>
                <Address address={unit.address} handleChange={handleChangeAddress} />
              </Collapse>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Button
              style={{
                borderStyle: "dashed",
                borderWidth: 2,
                borderRadius: 10,
              }}
              onClick={handleAddAddress}
            >
              Addressdaten hinzufügen
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Personentyp</InputLabel>
          <Select
            name="crewType"
            size="small"
            value={unit.crewType}
            label="Personentyp"
            onChange={(e: any) => handleChange(e)}
            //defaultValue={crewTypes["Sonstige"][0]}
          >
            {crewTypes
              .find((d) => d.orgaType === OrganisationTypes.Sonstige)!
              .crewNames.map((menuItem: string) => (
                <MenuItem key={menuItem} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={3}
          size="small"
          name="note"
          label="Notiz"
          value={unit.note}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default UnitEditPart;

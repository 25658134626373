import React, { Fragment, useState } from "react";
import { stores, useStores } from "../../stores/stores";
import {
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import Address from "./Address";

import Vehicles from "./../../vehicle/components/Vehicles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import Feedbacks from "../../feedback/components/Feedbacks";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { TransitionProps } from "@mui/material/transitions";
import UnitsView from "../../unit/components/UnitsView";
import UnitsViewSingle from "../../unit/components/UnitsViewSingle";
import Button from "../../common/components/atomics/Button";
import IconButton from "../../common/components/atomics/IconButton";
import Grid from "../../common/components/atomics/Grid";
import Tooltip from "../../common/components/atomics/Tooltip";
import Paper from "../../common/components/atomics/Paper";
import ListItem from "../../common/components/atomics/ListItem";
import Divider from "../../common/components/atomics/Divider";
import TextField from "../../common/components/atomics/TextField";
import ListItemButton from "../../common/components/atomics/ListItemButton";
import { IDocumentation, newAddress } from "../../model/documentation";
import { newMetaData } from "../../common/types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentationDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);

  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const documentation = useStores(
    "documentation",
    (data) => data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId)!
  );

  const changeSelectedDocumentationId = stores.ui.getState().update;
  const changeDocumentation = stores.documentation.getState().update;

  const handleDocumentationPrint = () => {
    window.open("/docu/detail/print");
  };

  const handleDelete = () => {
    setOpen(false);
    changeDocumentation((data) => {
      data.documentations = data.documentations.filter((d) => d.id != selectedDocumentationId);
    });
    changeDocumentation((data) => {
      data.vehicles = data.vehicles.filter((d) => d.documentationId != selectedDocumentationId);
    });
    changeDocumentation((data) => {
      data.feedbacks = data.feedbacks.filter((d) => d.documentationId != selectedDocumentationId);
    });
    changeDocumentation((data) => {
      data.crews = data.crews.filter((d) => d.documentationId != selectedDocumentationId);
    });
    changeSelectedDocumentationId((data) => {
      data.selectedDocumentationId = "";
    });
    changeSelectedDocumentationId((data) => {
      data.selectedMesureId = "";
    });
    navigate("/dashboard");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeDocumentation((data: any) => {
      data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId).address[e.target.name] =
        e.target.value;
    });
  };
  const handleChangeMetaData = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeDocumentation((data: any) => {
      data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId).metaData[e.target.name] =
        e.target.value;
    });
  };

  const handleAddAddress = () => {
    changeDocumentation((data: any) => {
      data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId).address = newAddress();
    });
    setOpenAddress(true);
  };

  const handleAddMetaData = () => {
    changeDocumentation((data: any) => {
      data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId).metaData = newMetaData();
    });
  };

  return (
    <Paper>
      <Grid pb={1} pl={1} pr={1} container spacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <ListItem
            secondaryAction={
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip title="Dokumentation Druckansicht" onClick={handleDocumentationPrint}>
                    <IconButton>
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Dokumentation löschen">
                    <IconButton onClick={() => setOpen(true)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          ></ListItem>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container spacing={2} xs={12}>
          <Grid item xs={4}>
            <TextField
              name="missionNumber"
              label="Einsatznummer"
              value={documentation.missionNumber}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                changeDocumentation((data) => {
                  data.documentations.find((d) => d.id == selectedDocumentationId)!.missionNumber = e.target.value;
                })
              }
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="missionKeyword"
              label="Einsatzstichwort"
              value={documentation.missionKeyword}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                changeDocumentation((data) => {
                  data.documentations.find((d) => d.id == selectedDocumentationId)!.missionKeyword = e.target.value;
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {documentation.address ? (
            <Grid>
              <ListItemButton onClick={() => setOpenAddress(!openAddress)}>
                <ListItemText primary="Adressdaten" />
                <ListItemIcon>{openAddress ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              </ListItemButton>
              <Grid m={2}>
                <Collapse in={openAddress} timeout="auto" unmountOnExit>
                  <Address address={documentation.address} handleChange={handleChangeAddress} />
                </Collapse>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Button
                style={{
                  borderStyle: "dashed",
                  borderWidth: 2,
                  borderRadius: 10,
                }}
                onClick={handleAddAddress}
              >
                Addressdaten hinzufügen
              </Button>
            </Grid>
          )}
        </Grid>
        {documentation.modules.vehicleManager && (
          <Grid item xs={6}>
            <Vehicles />
          </Grid>
        )}

        {documentation.modules.vehicleManager ? (
          <Grid item xs={6}>
            <Feedbacks />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={6}>
              {/* <UnitsViewSingle /> */}
            </Grid>
            <Grid item xs={6}>
              <Feedbacks />
            </Grid>
          </React.Fragment>
        )}

        {/* <Grid item xs={12}>
          <Grid item>
            <AddUnit />
          </Grid>
        </Grid> */}
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Löschen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Soll die Dokumentation wirklich gelöscht werden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>nein</Button>
          <Button onClick={handleDelete}>ja</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DocumentationDetail;

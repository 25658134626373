import { OrganisationTypes } from "../../model/modeltypes";
import { IUnit } from "../../model/unit";
import { IVehicle, newVehicle } from "../../model/vehicle";
import { useStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const useVehicle = (insertId?: string) => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const shiftTemplates = useStores("shiftTemplate", (data) => data);
  // const changeDocumentation = stores.documentation.getState().update;

  const vehiclesList = useStores("documentation", (data) =>
    data.vehicles
      .filter((d: IVehicle) => d.documentationId === selectedDocumentationId)
      .sort((a: IVehicle, b: IVehicle) => (a.vehicleType > b.vehicleType ? 1 : b.vehicleType > a.vehicleType ? -1 : 0))
  );
  const crewsList = useStores("documentation", (data) => {
    const list = data.crews.filter(
      (d: IUnit) =>
        d.documentationId === selectedDocumentationId &&
        d.vehicleId ===
          vehiclesList
            .filter((e: IVehicle) => d.vehicleId === e.id && e.vehicleType === OrganisationTypes.Feuerwehr)!
            .map((f: IVehicle) => f.id)
            .toString()
    );
    return list;
  });
  const unitsList = useStores("documentation", (data) =>
    data.crews.filter((d) => d.documentationId === selectedDocumentationId)
  );

  const copyVehicle = (vehicle: IVehicle, docuId: string) => {
    let _vehicle = { ...vehicle };
    _vehicle.id = uuidv4();
    _vehicle.documentationId = docuId;
    // changeDocumentation((data) => {
    //   data.vehicles.push(_vehicle);
    // });
    return _vehicle;
  };

  const vehicleTemplates = [
    {
      vehicleName: "Feuerwehr",
      vehicle: newVehicle(insertId!, OrganisationTypes.Feuerwehr, ""),
    },
    {
      vehicleName: "Rettungsdienst",
      vehicle: newVehicle(insertId!, OrganisationTypes.Rettungsdienst, ""),
    },
    {
      vehicleName: "Polizei",
      vehicle: newVehicle(insertId!, OrganisationTypes.Polizei, ""),
    },
    {
      vehicleName: "Sonstige",
      vehicle: newVehicle(insertId!, OrganisationTypes.Sonstige, ""),
    },
  ];

  return { vehicleTemplates, copyVehicle, vehiclesList, unitsList, crewsList, shiftTemplates };
};

export default useVehicle;

import { v4 as uuidv4 } from "uuid";
import { IModelbase } from "./modelbase";



export interface IMesure extends IModelbase {
  mesureDate: string;
  deviceSensor: string;
  searchPoint: string;
  sensorUnit: string;
  value: string;
}


export const newMesure = (sensor: string, searchPoint: string, sensorUnit: string, value: string) => {
  return {
    id: uuidv4(),
    mesureDate: new Date().toLocaleString(),
    deviceSensor: sensor,
    searchPoint: searchPoint,
    sensorUnit: sensorUnit,
    value: value,
  } as IMesure;
};

import { ITemplateDocumentation } from "./../../model/templateDocumentation";
import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { stores, useStores } from "../../stores/stores";
import { ApiCall } from "../../stores/api";
import { IMesureDevice } from "../../model/templateMesureDevice";

const useApi = () => {
  const [token, setToken] = useState("");
  const changeRemote = stores.remote.getState().update;
  const changeCache = stores.cache.getState().update;

  const resetRemote = stores.remote.getState().reset;
  const resetCache = stores.cache.getState().reset;
  const resetUi = stores.ui.getState().reset;
  const { getAccessTokenSilently } = useAuth0();

  //   useEffect(() => {
  //     (async () => {
  //       if (isAuthenticated)
  //         try {
  //           setToken(await getAccessTokenSilently());
  //         } catch (e) {
  //           // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
  //           console.error(e);
  //         }
  //     })();
  //   }, [getAccessTokenSilently, isAuthenticated]);

  const getToken = async () => {
    if (token === "") {
      let tk = await getAccessTokenSilently();
      setToken(tk);
      return tk;
    }
    return token;
  };

  const getTokenAndSync = async () => {
    let tk = "";
    if (token === "") {
      tk = await getAccessTokenSilently();
      setToken(tk);
    }

    const apiStatic = new ApiCall<ITemplateDocumentation>("apiStaticRoute", tk);
    const apiApp = new ApiCall<ITemplateDocumentation>("apiAppRoute", tk);

    const fetchTempDocus = async (): Promise<ITemplateDocumentation[]> => {
      let list = await apiApp.getAll("templates/documentations");
      return list!;
    };
    const fetchTempMD = async (): Promise<ITemplateDocumentation[]> => {
      let list = await apiApp.getAll("templates/mesuredevices");
      return list!;
    };

    const fetchCrewTypes = async (): Promise<ITemplateDocumentation[]> => {
      let list = await apiStatic.getAll("crewtypes");
      return list!;
    };

    const fetchRadioNames = async (): Promise<ITemplateDocumentation[]> => {
      let list = await apiStatic.getAll("radionames");
      return list!;
    };

    const fetching1 = async () => {
      return await fetchTempDocus();
    };
    const fetching2 = async () => {
      return await fetchTempMD();
    };
    const fetching3 = async () => {
      return await fetchCrewTypes();
    };
    const fetching4 = async () => {
      return await fetchRadioNames();
    };

    let fetch1 = false;
    let fetch2 = false;

    fetching1().then((list) => {
      if (Array.isArray(list)) {
        changeRemote((data: any) => {
          data.documentationTemplates = list;
        });
        changeCache((data: any) => {
          data.fetchTemplates = true;
        });
      }
    });

    fetching2().then((list) => {
      if (Array.isArray(list)) {
        changeRemote((data: any) => {
          data.mesureDeviceTemplates = list;
        });
        changeCache((data: any) => {
          data.fetchTemplates = true;
        });
      }
    });

    fetching3().then((list) => {
      if (Array.isArray(list)) {
        changeRemote((data: any) => {
          data.crewTypes = list;
        });
        fetch1 = true;
        if (fetch1 === true && fetch2 === true) {
          changeCache((data: any) => {
            data.fetchStatic = true;
          });
        }
      }
    });

    fetching4().then((list) => {
      if (Array.isArray(list)) {
        changeRemote((data: any) => {
          data.radioNames = list;
        });
        fetch2 = true;
        if (fetch1 === true && fetch2 === true) {
          changeCache((data: any) => {
            data.fetchStatic = true;
          });
        }
      }
    });
  };

  const logoutProcedure = () => {
    resetUi();
    resetCache();
    resetRemote();
  };

  const updateTemplateDocumentation = async (model: ITemplateDocumentation) => {
    const apiApp = new ApiCall<ITemplateDocumentation>("apiAppRoute", await getToken());
    var result = await apiApp.update(model, "templates/documentation");
    return result;
  };

  const updateTemplateMesureDevice = async (model: IMesureDevice) => {
    const apiApp = new ApiCall<IMesureDevice>("apiAppRoute", await getToken());
    var result = await apiApp.update(model, "templates/mesuredevice");
    return result;
  };

  return {
    token,
    getToken,
    getTokenAndSync,
    logoutProcedure,
    updateTemplateDocumentation,
    updateTemplateMesureDevice,
  };
};

export default useApi;

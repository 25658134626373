import React from "react";

import CrewListItem from "./CrewListItem";
import List from "../../common/components/atomics/List";
import Paper from "../../common/components/atomics/Paper";
import { IUnit } from "../../model/unit";

const CrewList = (props: any) => {
  const { crewsList, vehicleType, disabled, changeCrew } = props;

  return (
    <List dense sx={{ width: "100%" }}>
      <Paper>
        {crewsList.map((item: IUnit, i: any) => (
          <CrewListItem
            key={item.id}
            number={i + 1}
            item={item}
            disabled={disabled}
            vehicleType={vehicleType}
            changeCrew={changeCrew}
          ></CrewListItem>
        ))}
      </Paper>
    </List>
  );
};

export default CrewList;

import React from "react";
import MuiTabPanel from "@mui/lab/TabPanel";
import { ITabPanel } from "./types";

const TabPanel = React.forwardRef<HTMLDivElement, ITabPanel>(({ children, ...props }, ref) => {
  return (
    <MuiTabPanel ref={ref} {...props}>
      {children}
    </MuiTabPanel>
  );
});

export default TabPanel;

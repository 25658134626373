import React, { useState } from "react";
import Stack from "../common/components/atomics/Stack";
import TextField from "../common/components/atomics/TextField";
import { useNavigate } from "react-router-dom";
import { stores, useStores } from "../stores/stores";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import Grid from "../common/components/atomics/Grid";
import Paper from "../common/components/atomics/Paper";
import { StoresDataType } from "../stores/storeTypes";
import useManagementApi from "../common/hooks/useManagementApi";

const AddDevice = (props: any) => {
  let navigate = useNavigate();
  const { fireDepartmentInviteMember } = useManagementApi();
  const changeRemote = stores.remote.getState().update;
  const fireDepartment = useStores("remote", (data) => data.fireDepartment!);
  const [memberEmail, setmemberEmail] = useState("");

  const handleInvite = async () => {
    const isInvited = await fireDepartmentInviteMember(memberEmail, fireDepartment.id);
    console.log("Inviting is: " + isInvited);
    navigate("/account");
  };

  return (
    <Paper variant="outlined" m={2}>
      <Grid container p={2}>
        <Grid item xs={12} mb={2}>
          <Typography>Neuen Benutzer einladen</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <TextField
              size="small"
              name="email"
              label="Einladungsemailadresse"
              value={memberEmail}
              fullWidth
              onChange={(e) => setmemberEmail(e.target.value)}
            />
            <Button variant="contained" onClick={() => handleInvite()}>
              Einladen
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Zurück
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddDevice;

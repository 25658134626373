import React from "react";
import { ListItemAvatar as MuiListItemAvatar } from "@mui/material";
import { IListItemAvatar } from "./types";

const ListItemAvatar = React.forwardRef<HTMLDivElement, IListItemAvatar>(({ children, ...props }, ref) => {
  return (
    <MuiListItemAvatar ref={ref} {...props}>
      {children}
    </MuiListItemAvatar>
  );
});

export default ListItemAvatar;

import React from "react";
import Card from "./components/atomics/Card";
import Box from "./components/atomics/Box";
import Typography from "./components/atomics/Typography";
import Button from "./components/atomics/Button";
import { useNavigate } from "react-router-dom";
import { CardMedia, Container } from "@mui/material";
import Grid from "./components/atomics/Grid";
import CardContent from "./components/atomics/CardContent";

const WelcomeView = (props: any) => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Einsatzdokumentation",
      description: "Erstelle Einsatzdokumentationen mit beliebig vielen Fahrzeugen.",
      imageUrl: "/static/img/icon_docu.png", // Ersetzen Sie dies durch Ihren Bildpfad
    },
    {
      title: "Messprotokolle",
      description: "Einfache Dokumentation in einem Messeinsatz. Ordne die Messungen direkt einem Trupp zu.",
      imageUrl: "/static/img/icon_mess.png", // Ersetzen Sie dies durch Ihren Bildpfad
    },
    {
      title: "Personenaufnahme",
      description: "Dokumentiere Personen an der Einsatzstelle und behalte die Übersicht.",
      imageUrl: "/static/img/icon_person.png", // Ersetzen Sie dies durch Ihren Bildpfad
    },
  ];

  return (
    <Box>
      <Card>
        <CardMedia component="img" height="140" image="/static/img/welcome_2.png" />
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="div">
            Einsatzdoku
          </Typography> */}

          <Grid container>
            <Grid xs={8} item>
              <Typography variant="h6">- Erstelle modulare Dokumentationen von Einsatzgeschehen</Typography>
              <Typography variant="h6">- Digitalisierung bis kurz vor die Einsatzstelle</Typography>
              <Typography variant="h6">- Vorplanung der verfügbaren Kräfte</Typography>
              <Typography variant="h6">- Schnelle Rückmeldung und Anforderungen bis in die Leitstelle</Typography>
              <Typography variant="h6">- Effiziente Stabsarbeit</Typography>
              <Typography variant="h6">- Koordination von Bereitstellungsräumen</Typography>
            </Grid>
            <Grid xs={4} item alignSelf="center">
              <Button variant="contained">Anfragen</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Feature-Bereich */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "20px",
        }}
      >
        {features.map((feature, index) => (
          <Card key={index} sx={{ maxWidth: 345, margin: "20px" }}>
            <CardMedia component="img" height="140" image={feature.imageUrl} alt={feature.title} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {feature.title}
              </Typography>
              <Typography variant="body2" color="secondary">
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default WelcomeView;

import React from "react";
import { ListItem as MuiListItem } from "@mui/material";
import { IListItem } from "./types";

const ListItem = React.forwardRef<HTMLLIElement, IListItem>(({ children, ...props }, ref) => {
  return (
    <MuiListItem ref={ref} {...props}>
      {children}
    </MuiListItem>
  );
});

export default ListItem;

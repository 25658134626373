import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import { ITooltip } from "./types"; // Import your Tooltip props type

const Tooltip = React.forwardRef<HTMLDivElement, ITooltip>((props, ref) => {
  const { children, title, ...otherProps } = props;

  return (
    <MuiTooltip title={title} ref={ref} {...otherProps}>
      {children}
    </MuiTooltip>
  );
});

export default Tooltip;

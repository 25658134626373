import {
  Autocomplete,
  Chip,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  AutocompleteChangeReason,
} from "@mui/material";
import React, { useState } from "react";
import { stores, useStores } from "../../stores/stores";
import useVehicle from "./../../vehicle/hooks/useVehicle";
import useFeedback from "../../feedback/hooks/useFeedback";

import Grid from "../../common/components/atomics/Grid";
import Typography from "../../common/components/atomics/Typography";
import List from "../../common/components/atomics/List";
import ListItem from "../../common/components/atomics/ListItem";
import ListItemText from "../../common/components/atomics/ListItemText";
import TextField from "../../common/components/atomics/TextField";
import Paper from "../../common/components/atomics/Paper";
import Divider from "../../common/components/atomics/Divider";
import Button from "../../common/components/atomics/Button";
import { IDocumentation } from "../../model/documentation";
import { IUnit } from "../../model/unit";
import { IMesurement } from "../../model/mesurement";
import { IMesure, newMesure } from "../../model/mesure";
import { IMesureDeviceSensor } from "../../model/templateMesureDevice";

const MesureDetail = () => {
  const [open, setOpen] = useState(false);
  const [sensor, setSensor] = useState({ sensor: "", unit: "" });
  const [mesureInput, setMesureInput] = useState("");
  const [mesureSearchPoint, setMesureSearchPoint] = useState("");
  const selectedMesurementId = useStores("ui", (data) => data.selectedMesureId);
  const mesurement = useStores(
    "documentation",
    (data) => data.mesurements.find((d: IMesurement) => d.id == selectedMesurementId)!
  );
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const documentation = useStores(
    "documentation",
    (data) => data.documentations.find((d: IDocumentation) => d.id == selectedDocumentationId)!
  );
  const changeDocumentation = stores.documentation.getState().update;
  const { addFeedback } = useFeedback();
  const { crewsList } = useVehicle();

  const handleAddMesure = () => {
    changeDocumentation((data) => {
      data.mesurements
        .find((d: IMesurement) => d.id == selectedMesurementId)!
        .mesureValues.push(newMesure(sensor.sensor, mesureSearchPoint, sensor.unit, mesureInput));
    });
    addFeedback(
      "Messgerät " +
        mesurement.device.name +
        " | Neue Messung mit Sensor '" +
        sensor.sensor +
        "' mit '" +
        mesureInput +
        " " +
        sensor.unit +
        "' erfasst.",
      "extended",
      true
    );

    setSensor({ sensor: "", unit: "" });
    setMesureInput("");
    setMesureSearchPoint("");
    setOpen(false);
  };

  const handleOpen = (sensor: IMesureDeviceSensor) => {
    setMesureInput("");
    setMesureSearchPoint("");
    setSensor(sensor);
    setOpen(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeDocumentation((data: any) => {
      data.mesurements.find((d: IMesurement) => d.id == selectedMesurementId)![e.target.name] = e.target.value;
    });
  };
  const handleChangeSearchPoint = (value: string[]) => {
    changeDocumentation((data) => {
      data.mesurements.find((d) => d.id == selectedMesurementId)!.searchPoints = value;
    });
  };

  const handleChangeAutocomplete = (value: IUnit[]) => {
    changeDocumentation((data) => {
      data.mesurements.find((d) => d.id == selectedMesurementId)!.crewIds = value.map((d) => d.id);
    });
  };

  // const handleChecked = (e) => {
  //   changeDocumentation((data) => {
  //     data.mesurements.find((d) => d.id == selectedMesurementId)![e.target.name] = e.target.checked;
  //   });
  // };

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={6} mt={1} ml={1}>
        <Typography>Details</Typography>
        <List dense>
          <ListItem>
            <ListItemText primary={mesurement.device.name}></ListItemText>
            <TextField
              name="deviceNumber"
              label="Gerätenummer"
              size="small"
              value={mesurement.deviceNumber}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
            />
          </ListItem>
          <ListItem>
            <TextField
              size="small"
              name="searchTarget"
              label="Erwarteter Stoff"
              value={mesurement.searchTarget}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
            />
          </ListItem>
        </List>
        <Grid item m={1}></Grid>
      </Grid>
      <Grid item xs={5.5} mt={1}>
        <Paper>
          <Grid container item xs={12}>
            <Typography p={2}>Sensorbelegung und Messwerte erfassen</Typography>
            {mesurement.device.sensors.map((s: IMesureDeviceSensor) => (
              <Grid item key={s.sensor} p={1}>
                <Paper variant="outlined">
                  <Button variant="contained" disabled={!mesurement.crewIds} onClick={() => handleOpen(s)}>
                    <ListItemText primary={s.sensor} secondary={s.unit}></ListItemText>
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} ml={1} mr={1}>
        <Autocomplete
          multiple
          id="tags-filled"
          freeSolo
          options={mesurement.searchPoints}
          value={mesurement.searchPoints}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="filled" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Messpunkte angeben" placeholder="Messpunkte" />
          )}
          onChange={(event: any, value: string[] | null) => handleChangeSearchPoint(value ? value : [])}
        />
      </Grid>
      <Grid item xs={12} ml={1} mr={1}>
        <TextField
          name="note"
          label="Notiz"
          value={mesurement.note}
          multiline
          rows={3}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
        />
      </Grid>

      <Grid item xs={12} mr={1} ml={1}>
        {documentation.modules.vehicleManager && (
          <Autocomplete
            multiple
            id="tags-crew"
            options={crewsList.filter((d) => d.name != "")}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={crewsList.filter((d) => mesurement.crewIds!.includes(d.id))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Einsatzkräfte auswählen" placeholder="Einsatzkraft auswählen" />
            )}
            onChange={(event: any, value: IUnit[]) => handleChangeAutocomplete(value)}
          />
        )}
      </Grid>
      <Grid item xs={12} mr={1} ml={1}>
        <Typography>Erfasste Messwerte | {mesurement.mesureValues.length}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item container xs={12} mr={1} ml={1}>
          <Grid item xs={3}>
            <Typography>Messzeit</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Messpunkt</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Sensor</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Wert</Typography>
          </Grid>
        </Grid>
        <Divider />
        {mesurement.mesureValues.map((mesure: IMesure) => (
          <Grid item container xs={12} mr={1} ml={1} key={mesure.id}>
            <Grid item xs={3}>
              <Typography>{mesure.mesureDate}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{mesure.searchPoint}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{mesure.deviceSensor}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {mesure.value} {mesure.sensorUnit}
              </Typography>
            </Grid>
          </Grid>
          // <ListItem key={mesure.id} secondaryAction={<ListItemText primary={mesure.value}></ListItemText>}>
          //   <ListItemText primary={mesure.mesureDate}></ListItemText>
          //   <ListItemText primary="Messpunkt" secondary={mesure.searchPoint}></ListItemText>
          //   <ListItemText primary={mesure.deviceSensor} secondary={mesure.sensorUnit}></ListItemText>
          // </ListItem>
        ))}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Messung eintragen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Den Messwert für '{sensor.sensor}' ohne die Einheit '{sensor.unit}' eintragen
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="value"
            value={mesureInput}
            onChange={(e) => setMesureInput(e.target.value)}
            label={"Messwert in " + sensor.unit}
            fullWidth
            variant="standard"
          />
          {mesurement.searchPoints.length > 0 && (
            <React.Fragment>
              <DialogContentText mt={2}>Optional den Messpunkt auswählen</DialogContentText>
              <Autocomplete
                freeSolo
                id="tags-searchPpoint"
                options={mesurement.searchPoints}
                filterSelectedOptions
                defaultValue=""
                value={mesureSearchPoint}
                renderInput={(params) => (
                  <TextField {...params} label="Messpunkt auswählen" placeholder="Messpunkt auswählen" />
                )}
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: string | null,
                  reason: AutocompleteChangeReason
                ) => setMesureSearchPoint(value ? value : "")}
              />
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Abbrechen</Button>
          <Button onClick={() => handleAddMesure()} disabled={mesureInput == ""}>
            Messung eintragen
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default MesureDetail;

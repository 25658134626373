import React, { useState } from "react";
import Stack from "../common/components/atomics/Stack";
import TextField from "../common/components/atomics/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { stores, useStores } from "../stores/stores";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import Grid from "../common/components/atomics/Grid";
import Paper from "../common/components/atomics/Paper";
import { IDevice } from "../model/device";
import { StoresDataType } from "../stores/storeTypes";
import useManagementApi from "../common/hooks/useManagementApi";

const DeviceDetail = (props: any) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { fireDepartmentUpdateDevice } = useManagementApi();
  const changeRemote = stores.remote.getState().update;
  const deviceLocal = useStores("remote", (data) => data.fireDepartment!.devices.find((d) => d.id === id));
  const [deviceName, setDeviceName] = useState(deviceLocal!.name);

  if (!deviceLocal) return <Typography>Kein Gerät geladen</Typography>;

  const handleSave = async () => {
    changeRemote((data: StoresDataType["remote"]) => {
      data.fireDepartment!.devices.find((d) => d.id === id)!.name = deviceName;
    });
    await fireDepartmentUpdateDevice({ ...deviceLocal, name: deviceName } as IDevice);
    navigate("/account");
  };

  return (
    <Paper variant="outlined" m={2}>
      <Grid container p={2}>
        <Grid item xs={12} mb={2}>
          <Typography>Geräte Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <TextField
              size="small"
              name="name"
              label="Anzeigename"
              value={deviceName}
              fullWidth
              onChange={(e) => setDeviceName(e.target.value)}
            />
            <Button variant="contained" onClick={() => handleSave()}>
              Aktualisieren
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Zurück
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DeviceDetail;

import React, { useState } from "react";
import { stores, useStores } from "../../stores/stores";
import useVehicle from "../hooks/useVehicle";
import VehicleEditList from "./VehicleEditList";
import AddVehicle from "./AddVehicle";
import useFeedback from "../../feedback/hooks/useFeedback";
import useUnit from "../../unit/hooks/useUnit";
import Paper from "../../common/components/atomics/Paper";
import Grid from "../../common/components/atomics/Grid";
import Typography from "../../common/components/atomics/Typography";
import { IVehicle, copyVehicle } from "../../model/vehicle";

const VehiclesView = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const [isWriting, setIsWriting] = useState(false);
  const { vehiclesList, unitsList, shiftTemplates } = useVehicle();
  const { addFeedback } = useFeedback();
  const { addUnit } = useUnit();

  const changeDocumentation = stores.documentation.getState().update;

  const handleAddVehicle = (vehicle: IVehicle) => {
    let _vehicle = copyVehicle(vehicle);

    if (vehicle.documentationId === shiftTemplates.date.toString()) {
      shiftTemplates.crews
        .filter((d) => d.vehicleId == vehicle.id)
        .forEach((f) => {
          let c = { ...f, documentationId: selectedDocumentationId, vehicleId: _vehicle.id };
          changeDocumentation((data) => {
            data.crews.push(c);
          });
        });
      _vehicle.documentationId = selectedDocumentationId;
    }

    changeDocumentation((data) => {
      data.vehicles.push(_vehicle);
    });
    if (vehicle.vehicleName !== "" || vehicle.bos !== "")
      addFeedback("Fahrzeug '" + vehicle.vehicleName + "' (" + vehicle.bos + ") wurde hinzugefügt", "extended", true);
    else addFeedback("Fahrzeugtyp '" + vehicle.vehicleType + "' wurde angefordert", "extended", true);
    setIsWriting(false);
  };

  const handleArrived = (vehicle: IVehicle) => {
    changeDocumentation((data) => {
      if (vehicle.isArrived) {
        data.vehicles.find((d: IVehicle) => d.id == vehicle.id)!.isRemoved = true;
      } else {
        data.vehicles.find((d: IVehicle) => d.id == vehicle.id)!.isArrived = true;
      }
    });

    if (vehicle.isArrived) {
      addFeedback("Fahrzeug " + vehicle.vehicleName + " hat den Einsatzort verlassen", "extended", false);
    } else {
      addFeedback("Fahrzeug " + vehicle.vehicleName + " ist am Einsatzort eingetroffen", "extended", false);
    }
  };

  const handleAddCrew = (vehicle: IVehicle) => {
    addUnit(selectedDocumentationId, "crew", vehicle.id);
    addFeedback("Neue Einsatzkraft dem Fahrzeug " + vehicle.vehicleName + " hinzugefügt", "extended", false);
  };

  const handleRequireVehicle = () => {
    setIsWriting(true);
  };

  return (
    <Paper>
      <Grid sx={{ p: 1 }} container spacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography>Fahrzeuge</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12}>
              <VehicleEditList
                changeVehicle={changeDocumentation}
                vehiclesList={vehiclesList}
                crewsList={unitsList}
                handleAddCrew={handleAddCrew}
                handleArrived={handleArrived}
              />
            </Grid>
            <Grid item container xs={12} spacing={2} rowSpacing={2}>
              <AddVehicle
                buttonLabel="Fahrzeug anfordern"
                insertId={selectedDocumentationId}
                handleAddVehicle={handleAddVehicle}
                setIsWriting={setIsWriting}
                isWriting={isWriting}
                handleRequireVehicle={handleRequireVehicle}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VehiclesView;

import React from "react";
import { useStores } from "../../stores/stores";
import MesurePrint from "../../mesurement/components/MesurePrint";
import useVehicle from "../../vehicle/hooks/useVehicle";
import useFeedbackList from "../../feedback/hooks/useFeedbackList";
import UnitPrint from "../../unit/components/UnitPrint";
import Typography from "../../common/components/atomics/Typography";
import Divider from "../../common/components/atomics/Divider";
import Box from "../../common/components/atomics/Box";
import Grid from "../../common/components/atomics/Grid";
import List from "../../common/components/atomics/List";
import ListItem from "../../common/components/atomics/ListItem";
import Paper from "../../common/components/atomics/Paper";
import { IVehicle } from "../../model/vehicle";

const DocumentationPrint = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const documentation = useStores(
    "documentation",
    (data) => data.documentations.find((d) => d.id == selectedDocumentationId)!
  );
  const { vehiclesList, unitsList } = useVehicle();
  const { feedbacksList } = useFeedbackList();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Box sx={{ p: 1 }}>
        <Typography fontSize={10}>Dokumentation Anfang</Typography>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={2} rowSpacing={2} p={1}>
          <Grid item xs>
            <Paper elevation={2}>
              <List>
                <ListItem>Einsatznummer: {documentation.missionNumber}</ListItem>
                <ListItem>Stichwort: {documentation.missionKeyword}</ListItem>

                {documentation.address && (
                  <React.Fragment>
                    <ListItem>Adresse:</ListItem>
                    <ListItem>
                      <Typography>
                        {documentation.address?.street} {documentation.address?.houseNumber}{" "}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {documentation.address?.district} {documentation.address?.city}{" "}
                      </Typography>
                    </ListItem>
                  </React.Fragment>
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} rowSpacing={2} p={1}>
          <Grid item xs>
            {vehiclesList.map((item: IVehicle) => (
              <Paper elevation={2} key={item.id}>
                <Grid item xs m={1}>
                  <Grid item>
                    <Grid item>Fahrzeug: {item.vehicleName}</Grid>
                    <Grid item>BOS Kennung: {item.bos}</Grid>
                    <Grid item>Typ: {item.vehicleType}</Grid>
                    {unitsList.filter((d) => d.vehicleId == item.id).length > 0 ? (
                      <Grid>
                        <Grid item container>
                          <Grid item xs={3}>
                            Name
                          </Grid>
                          <Grid item xs={3}>
                            Zuordnung
                          </Grid>
                          <Grid item xs={3}>
                            PA
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Divider />
                        </Grid>
                        {unitsList
                          .filter((d) => d.vehicleId == item.id)
                          .map((crew) => (
                            <Grid key={crew.id} item xs={12} p={1} container>
                              <Grid item xs={3}>
                                {crew.name}
                              </Grid>
                              <Grid item xs={3}>
                                {crew.crewType}
                              </Grid>
                              <Grid item xs={3}>
                                {crew.canRespirator ? "ja" : "nein"}
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    ) : (
                      <Grid item>
                        <Typography color="primary">Keine Besatzung eingetragen</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} rowSpacing={2} p={1}>
          <Grid item xs>
            <Paper elevation={2}>
              <Grid item xs m={1}>
                <Grid item>
                  <Grid item>Rückmeldungen</Grid>
                  {feedbacksList.length > 0 ? (
                    <Grid>
                      <Grid item container>
                        <Grid item xs={2}>
                          Zeit
                        </Grid>
                        <Grid item xs={2}>
                          Priorität
                        </Grid>
                        <Grid item xs={8}>
                          Nachricht
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Divider />
                      </Grid>
                      {feedbacksList.map((feedback) => (
                        <Grid key={feedback.id} item xs={12} p={1} container>
                          <Grid item xs={2}>
                            {feedback.date}
                          </Grid>
                          <Grid item xs={2}>
                            {feedback.priority}
                            {feedback.vehicleBos && " (" + feedback.vehicleBos + ")"}
                          </Grid>
                          <Grid item xs={8}>
                            {feedback.feedback}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography color="primary">Keine Rückmeldungen vorhanden</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <UnitPrint />
      <MesurePrint />
      <Box sx={{ p: 1 }}>
        <Divider />
        <Typography fontSize={10}>Dokumentation Ende</Typography>
      </Box>
    </Box>
  );
};

export default DocumentationPrint;

import React from "react";
import ListItem from "../common/components/atomics/ListItem";
import ListItemAvatar from "../common/components/atomics/ListItemAvatar";
import ListItemText from "../common/components/atomics/ListItemText";
import Typography from "../common/components/atomics/Typography";
import Badge from "../common/components/atomics/Badge";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import IconButton from "../common/components/atomics/IconButton";
import { useNavigate } from "react-router-dom";

const MemberListItem = (props: any) => {
  const { item, number } = props;

  let navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate("/account/member/" + id);
  };

  return (
    <ListItem
      sx={{ height: 50 }}
      secondaryAction={
        <IconButton aria-label="remove" disabled onClick={() => handleEdit(item.id)}>
          <PersonRemoveIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Badge badgeContent={number} color="primary" />
      </ListItemAvatar>
      <ListItemText primary={item.displayName} />
    </ListItem>
  );
};

export default MemberListItem;

import React, { useEffect, useState } from "react";
import Box from "../common/components/atomics/Box";
import Paper from "../common/components/atomics/Paper";
import Grid from "../common/components/atomics/Grid";
import TextField from "../common/components/atomics/TextField";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import useManagementApi from "../common/hooks/useManagementApi";
import Stack from "../common/components/atomics/Stack";
import { stores } from "../stores/stores";

const AddFireDepartment = () => {
  const [name, setName] = useState("");
  const [deviceEmail, setDeviceEmail] = useState("");
  const [devicePassword, setDevicePassword] = useState("");
  const [devicePassword2, setDevicePassword2] = useState("");
  const [passwordMatch, setpasswordMatch] = useState(false);
  const { addFireDepartment, pullMember, pullDevice } = useManagementApi();
  const changeRemote = stores.remote.getState().update;

  useEffect(() => {
    if (devicePassword === devicePassword2 && devicePassword !== "") {
      setpasswordMatch(true);
    } else setpasswordMatch(false);
  }, [devicePassword, devicePassword2]);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    var fd = await addFireDepartment(name, deviceEmail, devicePassword);
    if (fd) {
      changeRemote(async (data: any) => {
        data.fireDepartment = fd;
        await pullMember();
        await pullDevice();
      });
    }
  };
  return (
    <Box sx={{ height: "100vh" }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          Neue Feuerwehr erstellen
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="name"
              label="Anzeigename"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="deviceEmail"
              label="Geräteaccount Email"
              value={deviceEmail}
              fullWidth
              onChange={(e) => setDeviceEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} container direction="row" spacing={1}>
            <Grid item xs={6}>
              <TextField
                size="small"
                type="password"
                fullWidth
                autoComplete="current-password"
                name="devicePassword"
                label="Geräteaccount Passwort"
                value={devicePassword}
                onChange={(e) => setDevicePassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                type="password"
                fullWidth
                autoComplete="current-password"
                name="devicePassword2"
                label="Passwort wiederholen"
                value={devicePassword2}
                onChange={(e) => setDevicePassword2(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={(e) => handleClick(e)} disabled={!passwordMatch}>
              Feuerwehr erstellen
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AddFireDepartment;

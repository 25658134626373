import React from "react";

import UnitListCard from "./UnitListCard";
import useUnit from "../hooks/useUnit";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "../../common/components/atomics/IconButton";
import Paper from "../../common/components/atomics/Paper";
import Grid from "../../common/components/atomics/Grid";
import ListItem from "../../common/components/atomics/ListItem";
import Tooltip from "../../common/components/atomics/Tooltip";
import Typography from "../../common/components/atomics/Typography";
import Divider from "../../common/components/atomics/Divider";

const UnitsView = () => {
  const { clientList } = useUnit();
  const handleUnitsPrint = () => {
    window.open("/docu/detail/unit/print");
  };

  return (
    <Paper>
      <Grid pb={1} pl={1} pr={1} container spacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <ListItem
            secondaryAction={
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip title="Personendokumentation Druckansicht" onClick={handleUnitsPrint}>
                    <IconButton>
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          >
            <Typography>Personendokumentation | {clientList.length} Personen</Typography>
          </ListItem>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined">
            <Grid item xs={12} m={1}>
              <UnitListCard />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UnitsView;

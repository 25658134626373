import { Checkbox, MenuItem, FormGroup, FormControlLabel, Select } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "../../common/components/atomics/IconButton";
import ListItem from "../../common/components/atomics/ListItem";
import ListItemAvatar from "../../common/components/atomics/ListItemAvatar";
import Avatar from "../../common/components/atomics/Avatar";
import Grid from "../../common/components/atomics/Grid";
import TextField from "../../common/components/atomics/TextField";
import { IUnit } from "../../model/unit";
import { useStores } from "../../stores/stores";
import { OrganisationTypes } from "../../model/modeltypes";

const CrewListItem = (props: any) => {
  const { item, vehicleType, disabled, changeCrew } = props;
  // const changeDocumentation = stores.documentation.getState().update;
  const crewTypes = useStores("remote", (data) => data.crewTypes);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeCrew((data: any) => {
      data.crews.find((d: IUnit) => d.id == item.id)![e.target.name] = e.target.value;
    });
  };

  const handleChecked = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked: boolean) => {
    changeCrew((data: any) => {
      data.crews.find((d: IUnit) => d.id == item.id)![e.target.name] = checked;
    });
  };

  const handleDelete = (item: IUnit) => {
    changeCrew((data: any) => {
      data.crews = data.crews.filter((d: IUnit) => d.id != item.id);
    });
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton disabled={item.isUsed || disabled} onClick={() => handleDelete(item)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <TextField
            disabled={disabled}
            name="name"
            label="Name"
            size="small"
            value={item.name}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            disabled={disabled}
            name="crewType"
            size="small"
            value={item.crewType}
            onChange={(e: any) => handleChange(e)}
          >
            {crewTypes
              .find((d) => d.orgaType === vehicleType)!
              .crewNames.map((menuItem: string) => (
                <MenuItem key={menuItem} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        {vehicleType === OrganisationTypes.Feuerwehr && (
          <Grid item mr={2}>
            <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    disabled={disabled}
                    name="canRespirator"
                    size="small"
                    checked={item.canRespirator}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChecked(e, checked)}
                  />
                }
                label="PA"
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

export default CrewListItem;

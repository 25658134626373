import { persist, createJSONStorage, StateStorage } from "zustand/middleware";
import { createStore, initStores } from "@colorfy-software/zfy";
import { StoresDataType } from "./storeTypes";
import { get, set, del } from "idb-keyval";
import { newUnit } from "../model/unit";

export const dbVersion: number = 0.41;

const initialStateA: StoresDataType["documentation"] = {
  documentations: [],
  mesurements: [],
  feedbacks: [],
  vehicles: [],
  crews: [],
};

const initialStateB: StoresDataType["shiftTemplate"] = {
  date: 0,
  station: "1",
  vehicles: [],
  crews: [],
};

const initialStateRemote: StoresDataType["remote"] = {
  documentationTemplates: [],
  mesureDeviceTemplates: [],
  crewTypes: [],
  radioNames: [],
  fireDepartment: undefined,
};

const initialStateUi: StoresDataType["ui"] = {
  pageName: "",
  selectedDocumentationId: "",
  selectedMesureId: "",
  selectedDeviceId: "",
  selectedAccountTab: "1",
  selectedDocumentationTab: "1",
  vehicleFeedback: undefined,
  snackPack: [],
  snackOpen: false,
  snackMessage: undefined,
};

const initialStateC: StoresDataType["cache"] = {
  formUnit: newUnit("", "client"),
  selectedUnitId: "",
  valueSelectionTemplate: [],
  fetchTemplates: false,
  fetchStatic: false,
  isAuth: false,
  member: undefined,
  device: undefined,
};

// Custom storage object
const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    //console.log(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    //console.log(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    //console.log(name, "has been deleted");
    await del(name);
  },
};

const storeA = createStore<StoresDataType["documentation"]>("documentation", initialStateA, {
  persist: {
    version: dbVersion,
    getStorage: () => storage,
  },
});
const storeB = createStore<StoresDataType["shiftTemplate"]>("shiftTemplate", initialStateB, {
  persist: {
    version: dbVersion,
    getStorage: () => storage,
  },
});

const storeUi = createStore<StoresDataType["ui"]>("ui", initialStateUi, {
  persist: {
    version: dbVersion,
    getStorage: () => storage,
  },
});

const storeRemote = createStore<StoresDataType["remote"]>("remote", initialStateRemote, {
  persist: {
    version: dbVersion,
    getStorage: () => storage,
  },
});

const storeCache = createStore<StoresDataType["cache"]>("cache", initialStateC);

export { storeA, storeB, storeUi, storeRemote };

export const { stores, useStores } = initStores<StoresDataType>([
  storeA,
  storeB,
  storeUi,
  storeRemote,
  storeCache,
]);

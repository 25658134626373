import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stores, useStores } from "../../stores/stores";
import DocuTemplateCard from "./DocuTemplateCard";
import useVehicle from "../../vehicle/hooks/useVehicle";
import useFeedback from "../../feedback/hooks/useFeedback";
import useUnit from "../../unit/hooks/useUnit";
import Grid from "../../common/components/atomics/Grid";
import ToggleButtonGroup from "../../common/components/atomics/ToggleButtonGroup";
import ToggleButton from "../../common/components/atomics/ToggleButton";
import { ITemplateCard, newDocumentation } from "../../model/documentation";
import { newVehicle } from "../../model/vehicle";
import { ITemplateVehicle } from "../../model/templateDocumentation";
import Box from "../../common/components/atomics/Box";

const DocumentationNew = () => {
  const navigate = useNavigate();
  // const templates = useStores("ui", (data) => data.templates);
  const formCache = useStores("cache", (data) => data.valueSelectionTemplate);
  const remoteTemplates = useStores("remote", (data) => data.documentationTemplates);
  const { shiftTemplates, copyVehicle } = useVehicle();
  const { addUnit, copyUnit } = useUnit();
  const changeDocumentation = stores.documentation.getState().update;
  const changeCache = stores.cache.getState().update;
  const { addFeedback } = useFeedback();
  const cardList: ITemplateCard[] = [];

  useEffect(() => {
    remoteTemplates.forEach((docTempl) => {
      changeCache((data) => {
        data.valueSelectionTemplate.push({
          templateId: docTempl.id,
          station: shiftTemplates.station ? shiftTemplates.station : "1",
          extended: docTempl.toggleExtended ? "1" : "0",
        });
      });
    });
  }, []);

  remoteTemplates.forEach((docTempl) => {
    cardList.push({
      headline: docTempl.templateName,
      description: docTempl.description,
      linkName: docTempl.linkDescription,
      selection: (
        <Grid container spacing={2} rowSpacing={3}>
          {docTempl.toggleStation && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                value={formCache.find((d) => d.templateId == docTempl.id)?.station}
                exclusive
                onChange={(e, val) => {
                  if (val !== null) {
                    changeCache((data) => {
                      data.valueSelectionTemplate.find((d) => d.templateId === docTempl.id)!.station = val;
                    });
                  }
                }}
              >
                <ToggleButton value="1">Feuerwache 1</ToggleButton>
                <ToggleButton value="2">Feuerwache 2</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          {docTempl.toggleExtended && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                value={formCache.find((d) => d.templateId == docTempl.id)?.extended}
                exclusive
                onChange={(e, val) => {
                  if (val !== null)
                    changeCache((data) => {
                      data.valueSelectionTemplate.find((d) => d.templateId === docTempl.id)!.extended = val;
                    });
                }}
              >
                <ToggleButton value="0">Löscheinheit</ToggleButton>
                <ToggleButton value="1">Löscheinheit + Ergänzung</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
        </Grid>
      ),
      handleClick: () =>
        processClick(
          docTempl.templateName,
          docTempl.id,
          formCache.find((d) => d.templateId == docTempl.id),
          docTempl.useVehicleManager,
          docTempl.useMesurement
        ),
    } as ITemplateCard);
  });

  const processClick = (
    name: string,
    templateName: string,
    valuesObj: any,
    vehicleManager: boolean,
    mesure: boolean
  ) => {
    let docu = newDocumentation(name, vehicleManager, mesure);
    changeDocumentation((data) => {
      data.documentations.push(docu);
    });
    changeSelectedDocumentation((data) => {
      data.selectedDocumentationId = docu.id;
    });
    addFeedback("Neue Dokumentation mit Vorlage '" + name + "' wurde begonnen", "extended", true, docu.id);
    const template = remoteTemplates.find((d) => d.id == templateName);
    template &&
      template.vehicles.map((v: ITemplateVehicle) => {
        var templateVehicleId = "";
        var vehicle = newVehicle(
          docu.id,
          v.orgaType,
          v.name,
          valuesObj.station + v.radioName,
          true,
          valuesObj.station
        );

        var searchVehicle = shiftTemplates.vehicles.find((d) => d.bos === valuesObj.station + v.radioName);
        if (searchVehicle) {
          templateVehicleId = searchVehicle.id;
          vehicle = copyVehicle(searchVehicle, docu.id);
        }
        changeDocumentation((data) => {
          data.vehicles.push(vehicle);
        });
        if (searchVehicle) {
          shiftTemplates.crews
            .filter((d) => d.vehicleId == templateVehicleId)
            .forEach((f) => {
              changeDocumentation((data) => {
                data.crews.push(copyUnit(f, docu.id, vehicle.id));
              });
            });
        } else {
          Array.from(Array(Number(v.crewCount)).keys()).map((unit) => {
            addUnit(docu.id, "crew", vehicle.id);
          });
        }
        addFeedback("Fahrzeug " + vehicle.vehicleName + " wurde hinzugefügt", "extended", false, docu.id);
      });
    if (valuesObj.extended == "1") {
      template!.vehiclesExtended!.map((v) => {
        var templateVehicleId = "";
        var vehicle = newVehicle(
          docu.id,
          v.orgaType,
          v.name,
          v.radioName![0] === "/"
            ? valuesObj.station == 1
              ? "2" + v.radioName
              : "1" + v.radioName
            : v.radioName,
          false,
          v.station ? v.station : valuesObj.station == 1 ? "2" : "1" + v.radioName
        );
        var searchVehicle = shiftTemplates.vehicles.find(
          (d) => d.bos === (valuesObj.station == 1 ? "2" + v.radioName : "1" + v.radioName)
        );
        if (searchVehicle) {
          templateVehicleId = searchVehicle.id;
          vehicle = copyVehicle(searchVehicle, docu.id);
        }
        changeDocumentation((data) => {
          data.vehicles.push(vehicle);
        });
        if (searchVehicle) {
          shiftTemplates.crews
            .filter((d) => d.vehicleId == templateVehicleId)
            .forEach((f) => {
              // let c = { ...f, documentationId: docu.id, id: uuidv4() };
              changeDocumentation((data) => {
                data.crews.push(copyUnit(f, docu.id, vehicle.id));
              });
            });
        } else {
          if (v.crewCount) {
            Array.from(Array(Number(v.crewCount)).keys()).map((unit) => {
              addUnit(docu.id, "crew", vehicle.id);
            });
          }
        }
        addFeedback("Fahrzeug " + vehicle.vehicleName + " wurde hinzugefügt", "extended", false, docu.id);
      });
    }
    template!.vehiclesOther!.map((v) => {
      var vehicle = newVehicle(docu.id, v.orgaType, v.name, "", false, v.station);
      changeDocumentation((data) => {
        data.vehicles.push(vehicle);
      });
      Array.from(Array(Number(v.crewCount)).keys()).map((unit) => {
        addUnit(docu.id, "crew", vehicle.id);
      });
      addFeedback("Fahrzeug " + vehicle.vehicleName + " wurde hinzugefügt", "extended", false, docu.id);
    });

    navigate("/docu");
  };

  const changeSelectedDocumentation = stores.ui.getState().update;

  return (
    <Box sx={{ height: "100vh" }}>
      <Grid container spacing={1} rowSpacing={2}>
        {cardList.map((card) => (
          <DocuTemplateCard key={card.headline} {...card} />
        ))}
      </Grid>
    </Box>
  );
};

export default DocumentationNew;

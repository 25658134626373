import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "./components/atomics/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

import NotificationsIcon from "@mui/icons-material/Notifications";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useStores } from "../stores/stores";
import useVehicle from "../vehicle/hooks/useVehicle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Stack from "./components/atomics/Stack";
import Login from "./components/Login";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import Avatar from "./components/atomics/Avatar";
import Divider from "./components/atomics/Divider";
import { AccountCircle, PersonAdd, Settings } from "@mui/icons-material";
import Box from "./components/atomics/Box";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "./components/atomics/Button";
import useApi from "./hooks/useApi";
import useManagementApi from "./hooks/useManagementApi";
import { StoresDataType } from "../stores/storeTypes";

const Header = (props: any) => {
  const ui = useStores("ui", (data: StoresDataType["ui"]) => data);
  const cache = useStores("cache", (data: StoresDataType["cache"]) => data);
  const firedepartment = useStores("remote", (data: StoresDataType["remote"]) => data.fireDepartment);
  const { vehiclesList, crewsList } = useVehicle();

  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading, logout, loginWithPopup } = useAuth0();
  const { getTokenAndSync, logoutProcedure } = useApi();
  const { pullMember, pullDevice, pullFireDepartment } = useManagementApi();
  const device = useStores("cache", (data: StoresDataType["cache"]) => data.device);
  const selectedDeviceId = useStores("ui", (data) => data.selectedDeviceId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    logoutProcedure();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      getTokenAndSync();
      pullMember();
      pullDevice();
      pullFireDepartment();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const handleLogin = async () => {
    await loginWithPopup();
  };

  ////sx={{ display: { sm: "none", xs: "block" } }}
  return (
    <Box>
      <AppBar elevation={0} position="sticky">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <React.Fragment>
            {isAuthenticated && firedepartment && (
              <React.Fragment>
                <Stack direction="row" spacing={2}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => navigate("/dashboard")}
                    edge="start"
                  >
                    <HomeIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => navigate("/docu/new")}
                    edge="start"
                  >
                    <NoteAddIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => navigate("/docu/list")}
                    edge="start"
                  >
                    <ViewListIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => navigate("/settings")}
                    edge="start"
                  >
                    <PermContactCalendarIcon />
                  </IconButton>
                  <React.Fragment>
                    {cache.fetchStatic === true && cache.fetchTemplates === true ? (
                      <IconButton size="large" color="inherit">
                        <PublishedWithChangesIcon />
                      </IconButton>
                    ) : (
                      <IconButton size="large" color="inherit">
                        <SyncProblemIcon />
                      </IconButton>
                    )}
                  </React.Fragment>
                </Stack>
                <Typography color="inherit" component="div" sx={{ flexGrow: 1 }}>
                  {firedepartment.name}
                </Typography>
              </React.Fragment>
            )}
            {isAuthenticated && (
              <React.Fragment>
                <Typography color="inherit" component="div" sx={{ flexGrow: 1 }}></Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography>
                    {selectedDeviceId !== "" &&
                      firedepartment &&
                      firedepartment.devices.find((d) => d.id === selectedDeviceId)!.name}
                  </Typography>
                  <Tooltip title="Profil">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
          {!isAuthenticated && (
            <React.Fragment>
              <Typography color="inherit" component="div" sx={{ flexGrow: 1 }}>
                cleverdok.de | Einsatzdoku
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Button color="inherit" onClick={() => handleLogin()}>
                  Login
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/account")}>
          <Avatar /> Mein Account
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Einstellungen
        </MenuItem> */}
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;

import React from "react";
import { useStores } from "../../stores/stores";
import useUnit from "../hooks/useUnit";
import Box from "../../common/components/atomics/Box";
import Typography from "../../common/components/atomics/Typography";
import Divider from "../../common/components/atomics/Divider";
import Paper from "../../common/components/atomics/Paper";
import Grid from "../../common/components/atomics/Grid";
import { IUnit } from "../../model/unit";

const UnitPrint = () => {
  const selectedMesurementId = useStores("ui", (data) => data.selectedMesureId);
  const { clientList } = useUnit();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {clientList.length > 0 ? (
        <React.Fragment>
          <Box sx={{ p: 1 }}>
            <Typography fontSize={10}>Personendokumentation Anfang</Typography>
            <Divider />
          </Box>
          <Box>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs m={1}>
                <Paper elevation={2}>
                  <Grid>
                    <Grid item container m={1}>
                      <Grid item xs={3}>
                        Name
                      </Grid>
                      <Grid item xs={3}>
                        Adresse
                      </Grid>
                      <Grid item xs={3}>
                        Typ
                      </Grid>
                      <Grid item xs={3}>
                        Notiz
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    {clientList.map((crew: IUnit) => (
                      <Grid key={crew.id} item xs={12} container m={1}>
                        <Grid item xs={3}>
                          {crew.name}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>
                            {crew.address?.street} {crew.address?.houseNumber}{" "}
                          </Typography>
                          <Typography>
                            {crew.address?.district} {crew.address?.city}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          {crew.crewType}
                        </Grid>
                        <Grid item xs={3}>
                          {crew.note}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 1 }}>
            <Divider />
            <Typography fontSize={10}>Personendokumentation Ende</Typography>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Box>
  );
};

export default UnitPrint;

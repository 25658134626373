import React, { useState } from "react";
import Box from "../common/components/atomics/Box";
import Paper from "../common/components/atomics/Paper";
import Grid from "../common/components/atomics/Grid";
import TextField from "../common/components/atomics/TextField";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import useManagementApi from "../common/hooks/useManagementApi";
import { stores, useStores } from "../stores/stores";
import { IFireDepartment } from "../model/fireDepartment";
import MemberList from "./MemberList";
import DeviceList from "./DeviceList";
import InviteList from "./InviteList";
import Stack from "../common/components/atomics/Stack";

const FireDepartmentDetail = () => {
  const { updateFireDepartment, copyTemplatesMesureDevice } = useManagementApi();
  const device = useStores("cache", (data) => data.device);
  const fireDepartment = useStores("remote", (data) => data.fireDepartment!);
  const changeRemote = stores.remote.getState().update;
  const [name, setName] = useState(fireDepartment.name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeRemote((data: any) => {
      data.fireDepartment![e.target.name] = e.target.value;
    });
  };

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    changeRemote(async (data: any) => {
      data.fireDepartment.name = name;
    });

    var fd = await updateFireDepartment({ ...fireDepartment, name: name });
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          Aktive Feuerwehrinstanz
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="name"
              label="Anzeigename"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              name="Members"
              label="Mitgliederanzahl"
              value={fireDepartment.members.length}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              name="Geräte"
              label="Geräteanzahl"
              value={fireDepartment.devices.length}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="deviceMail"
              label="Geräteaccount-Email"
              value={fireDepartment.deviceUserEmail}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={(e) => copyTemplatesMesureDevice()}>
              Kopiere Templates
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={(e) => handleSave(e)}>
              Aktualisieren
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default FireDepartmentDetail;

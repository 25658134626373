import React, { useEffect, useState } from "react";
import { stores, useStores } from "../../stores/stores";

import useUnit from "../hooks/useUnit";
import UnitEditPart from "./UnitEditPart";
import UnitCard from "./UnitCard";
import useFeedback from "../../feedback/hooks/useFeedback";
import Button from "../../common/components/atomics/Button";
import Grid from "../../common/components/atomics/Grid";
import { newAddress } from "../../model/documentation";
import { newUnit } from "../../model/unit";

const AddUnitCard = (props: any) => {
  const { handleReset } = props;
  const [openAddress, setOpenAddress] = useState(false);
  const { addClient } = useUnit();
  const { addFeedback } = useFeedback();
  const formUnit = useStores("cache", (data) => data.formUnit!);
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const changeFormUnit = stores.cache.getState().update;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    changeFormUnit((data: any) => {
      data.formUnit.documentationId = selectedDocumentationId;
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeFormUnit((data: any) => {
      data.formUnit![e.target.name] = e.target.value;
    });
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeFormUnit((data: any) => {
      data.formUnit.address[e.target.name] = e.target.value;
    });
  };

  const handleAddAddress = () => {
    changeFormUnit((data: any) => {
      data.formUnit.address = newAddress();
    });
    setOpenAddress(true);
  };

  const handleAddUnit = () => {
    addClient(formUnit);
    addFeedback("Neue Person mit Name '" + formUnit.name + "' wurde aufgenommen.", "extended", true);
    changeFormUnit((data: any) => {
      data.formUnit = newUnit(selectedDocumentationId, "client");
    });
    setOpenAddress(false);
    handleReset();
  };

  return (
    <UnitCard
      unit={formUnit}
      isForm={handleReset != undefined ? true : false}
      mainContent={
        <Grid item container spacing={2} rowSpacing={2}>
          <UnitEditPart
            unit={formUnit}
            handleChange={handleChange}
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            handleChangeAddress={handleChangeAddress}
            handleAddAddress={handleAddAddress}
          />
          <Grid item xs={12}>
            <Button variant="outlined" disabled={formUnit.name == "" ? true : false} onClick={() => handleAddUnit()}>
              Person hinzufügen
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default AddUnitCard;

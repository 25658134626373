import { useStores } from "../../stores/stores";

const useFeedbackList = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const feedbacksList = useStores("documentation", (data) =>
    data.feedbacks.filter((d) => d.documentationId == selectedDocumentationId).reverse()
  );

  return { feedbacksList };
};

export default useFeedbackList;

import React from "react";
import FeedbackListItem from "./FeedbackListItem";
import Grid from "../../common/components/atomics/Grid";
import List from "../../common/components/atomics/List";
import { IFeedback } from "../../model/feedback";

const FeedbackList = (props: any) => {
  const { feedbacksList } = props;
  return (
    <Grid container item>
      <Grid item xs={12}>
        <List style={{ maxHeight: "800px", overflow: "auto" }}>
          {feedbacksList.map((item: IFeedback, i: any) => (
            <FeedbackListItem key={item.id} number={feedbacksList.length - i} item={item} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default FeedbackList;

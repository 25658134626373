import React, { useState } from "react";
import { stores, useStores } from "../stores/stores";
import {
  Paper,
  TextField,
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AddVehicle from "./../vehicle/components/AddVehicle";
import VehicleList from "../vehicle/components/VehicleList";
import useVehicle from "./../vehicle/hooks/useVehicle";
import VehicleEditList from "../vehicle/components/VehicleEditList";
import { TransitionProps } from "@mui/material/transitions";
import useUnit from "../unit/hooks/useUnit";
import Button from "./components/atomics/Button";
import Grid from "./components/atomics/Grid";
import { IVehicle } from "../model/vehicle";
import { newUnit } from "../model/unit";
import Box from "./components/atomics/Box";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SettingsView = () => {
  const { shiftTemplates } = useVehicle();
  const changeShiftTemplates = stores.shiftTemplate.getState().update;
  const [isWriting, setIsWriting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleRequireVehicle = () => {
    setIsWriting(true);
  };

  const handleAddCrew = (vehicle: IVehicle) => {
    changeShiftTemplates((data) => {
      data.crews.push(newUnit(shiftTemplates.date.toString(), "crew", vehicle.id));
    });
  };

  const handleDelete = () => {
    setOpen(false);
    changeShiftTemplates((data) => {
      data.date = 0;
      data.station = "1";
      data.vehicles = [];
      data.crews = [];
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddVehicle = (vehicle: IVehicle) => {
    let newVehicle = { ...vehicle };
    newVehicle.documentationId = shiftTemplates.date.toString();
    newVehicle.isArrived = true;
    newVehicle.station = shiftTemplates.station;
    changeShiftTemplates((data) => {
      data.vehicles.push(newVehicle);
    });

    setIsWriting(false);
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Grid sx={{ p: 1 }} container spacing={2} rowSpacing={2}>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={(e) =>
              changeShiftTemplates((data) => {
                data.date = Date.now();
              })
            }
          >
            Dienst planen
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            visibility:
              shiftTemplates.date || (shiftTemplates.vehicles && shiftTemplates.vehicles.length != 0)
                ? "visible"
                : "hidden",
          }}
        >
          <Button variant="contained" onClick={() => setOpen(true)}>
            Planung zurücksetzen
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date"
            size="small"
            label="Datum"
            value={
              shiftTemplates.date &&
              new Date(shiftTemplates.date).toLocaleDateString("de-DE", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            disabled={shiftTemplates.date == 0 ? true : false}
            color="primary"
            value={shiftTemplates.station ? shiftTemplates.station : "1"}
            exclusive
            onChange={(e, val) => {
              if (val !== null) {
                changeShiftTemplates((data) => {
                  data.station = val;
                });
              }
            }}
          >
            <ToggleButton fullWidth value="1">
              Feuerwache 1
            </ToggleButton>
            <ToggleButton fullWidth value="2">
              Feuerwache 2
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12}>
              <VehicleEditList
                changeVehicle={changeShiftTemplates}
                vehiclesList={shiftTemplates.vehicles}
                crewsList={shiftTemplates.crews}
                handleAddCrew={handleAddCrew}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <AddVehicle
            templateMode
            insertId={shiftTemplates.date}
            buttonLabel="Fahrzeug erstellen"
            disabled={shiftTemplates.date == 0 ? true : false}
            handleAddVehicle={handleAddVehicle}
            setIsWriting={setIsWriting}
            isWriting={isWriting}
            handleRequireVehicle={handleRequireVehicle}
          />
        </Grid>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Löschen"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Soll die Dienstplanung gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>nein</Button>
            <Button onClick={handleDelete}>ja</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default SettingsView;

import React from "react";
import { useStores } from "../../stores/stores";

function useMesurement() {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const mesureDeviceTemplates = useStores("remote", (data) => data.mesureDeviceTemplates);

  // let mesureDeviceTemplates = [
  //   {
  //     name: "X-am 5000",
  //     sensor: [
  //       { name: "Ex Nonan", unit: "%UEG" },
  //       { name: "CO", unit: "ppm" },
  //       { name: "CO2", unit: "ppm" },
  //       { name: "O2", unit: "Vol%" },
  //       { name: "H2S", unit: "ppm" },
  //     ],
  //   },
  //   {
  //     name: "X-am 8000",
  //     sensor: [
  //       { name: "Ex Ch4", unit: "%UEG" },
  //       { name: "H2", unit: "ppm" },
  //       { name: "HCN", unit: "ppm" },
  //       { name: "NO2", unit: "ppm" },
  //     ],
  //   },
  //   {
  //     name: "PID",
  //     sensor: [{ name: "Stoff", unit: "RF" }],
  //   },
  //   {
  //     name: "AD-6",
  //     sensor: [
  //       { name: "Dosis", unit: "uSv" },
  //       { name: "Dosisleistung", unit: "uSv/h" },
  //     ],
  //   },
  //   {
  //     name: "AD-K",
  //     sensor: [{ name: "Impulse/s", unit: "s-1" }],
  //   },
  //   {
  //     name: "Prüfröhrchen",
  //     sensor: [{ name: "Stoff", unit: "-" }],
  //   },
  // ] as DeviceType[];

  return { mesureDeviceTemplates };
}

export default useMesurement;

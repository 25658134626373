import { useEffect, useState } from "react";
import { IDashboardCard } from "../types";

const useDashboard = () => {
  const [cards, setCards] = useState<IDashboardCard[]>([]);

  useEffect(() => {
    let cardList = [];
    cardList.push({
      headline: "Einsatzdokumentation",
      description: "Dokumentation des Einsatzverlaufs mit Integration von Messprotokollen im Messeinsatz",
      linkName: "Neue Doku beginnen",
      path: "docu/new",
    } as IDashboardCard);
    cardList.push({
      headline: "Einsatzdokumentation",
      description: "Zeige bestehende und noch nicht exportierte Einsatzdokumentationen an",
      linkName: "Dokumentationen anzeigen",
      path: "docu/list",
    } as IDashboardCard);
    cardList.push({
      headline: "Diensteinstellungen",
      description: "Erstelle / ändere die Zuordnung von Mannschaft und Fahrzeugen für den aktuellen Dienst.",
      linkName: "Diensteinstellungen anzeigen",
      path: "settings",
    } as IDashboardCard);
    setCards(cardList);
    return () => {
      //cleanup
      setCards([]);
    };
  }, []);

  return { cards };
};

export default useDashboard;

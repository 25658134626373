import React from "react";
import { Box as MuiBox } from "@mui/material";
import { IBox } from "./types";

const Box: React.FC<IBox> = (props) => {
  return (
    <MuiBox component={props.component} sx={props.sx}>
      {props.children}
    </MuiBox>
  );
};

export default Box;

import React from "react";
import Grid from "../../common/components/atomics/Grid";
import ListItemButton from "../../common/components/atomics/ListItemButton";
import ListItemAvatar from "../../common/components/atomics/ListItemAvatar";
import Badge from "../../common/components/atomics/Badge";
import ListItemText from "../../common/components/atomics/ListItemText";

const MesurementListItem = (props: any) => {
  const { item, onClick, number } = props;

  return (
    <Grid item xs={12}>
      <ListItemButton divider onClick={() => onClick(item.id)}>
        <ListItemAvatar>
          <Badge badgeContent={number} color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={item.device.name}
          secondary={item.deviceNumber ? "Gerätenummer: " + item.deviceNumber : "Gerätenummer eintragen..."}
        />
      </ListItemButton>
    </Grid>
  );
};

export default MesurementListItem;

import React from "react";
import { useStores } from "../../stores/stores";
import { Chip } from "@mui/material";
import useMesurementList from "./../hooks/useMesurementList";
import useVehicle from "../../vehicle/hooks/useVehicle";
import Box from "../../common/components/atomics/Box";
import Typography from "../../common/components/atomics/Typography";
import Divider from "../../common/components/atomics/Divider";
import Grid from "../../common/components/atomics/Grid";
import Paper from "../../common/components/atomics/Paper";
import { IMesurement } from "../../model/mesurement";
import { IUnit } from "../../model/unit";

const MesurePrint = () => {
  const selectedMesurementId = useStores("ui", (data) => data.selectedMesureId);
  const { mesurementsList } = useMesurementList();
  const { crewsList } = useVehicle();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {mesurementsList.length > 0 ? (
        <React.Fragment>
          <Box sx={{ p: 1 }}>
            <Typography fontSize={10}>Messprotokoll Anfang</Typography>
            <Divider />
          </Box>
          <Box>
            <Grid container spacing={2} rowSpacing={2} p={1}>
              <Grid item xs>
                {mesurementsList.map((item: IMesurement) => (
                  <Paper elevation={2} key={item.id}>
                    <Grid item xs m={1}>
                      <Grid item xs={6}>
                        Messgerät: {item.device.name}
                      </Grid>
                      <Grid item>Erwarteter Stoff: {item.searchTarget}</Grid>
                      <Grid item container>
                        Messpunkte:
                        {item.searchPoints.map((d) => (
                          <Chip variant="outlined" key={d} size="small" label={d} />
                        ))}
                      </Grid>
                      <Grid item>Notiz: {item.note}</Grid>
                      {item.crewIds ? (
                        <Grid>
                          <Grid item xs={12}>
                            Messung durchgeführt durch:
                          </Grid>
                          <Grid item container>
                            <Grid item xs={3}>
                              Name
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Divider />
                          </Grid>
                          {crewsList
                            .filter((d) => item.crewIds!.includes(d.id))
                            .map((crew: IUnit) => (
                              <Grid key={crew.id} item xs={12} p={1} container>
                                <Grid item xs={3}>
                                  {crew.name}
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      ) : (
                        <Grid item>
                          <Typography color="primary">Kein Messtrupp eingetragen</Typography>
                        </Grid>
                      )}
                      {item.mesureValues ? (
                        <Grid>
                          <Grid item container>
                            <Grid item xs={3}>
                              Messzeit
                            </Grid>
                            <Grid item xs={3}>
                              Messpunkt
                            </Grid>
                            <Grid item xs={2}>
                              Sensor
                            </Grid>
                            <Grid item xs={1}>
                              Wert
                            </Grid>
                            <Grid item xs={1}>
                              Einheit
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Divider />
                          </Grid>
                          {item.mesureValues.map((values) => (
                            <Grid key={values.id} item xs={12} p={1} container>
                              <Grid item xs={3}>
                                {values.mesureDate}
                              </Grid>
                              <Grid item xs={3}>
                                {values.searchPoint}
                              </Grid>
                              <Grid item xs={2}>
                                {values.deviceSensor}
                              </Grid>
                              <Grid item xs={1}>
                                {values.value}
                              </Grid>
                              <Grid item xs={1}>
                                {values.sensorUnit}
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid item>
                          <Typography color="primary">Keine Messung eingetragen</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                ))}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 1 }}>
            <Divider />
            <Typography fontSize={10}>Messprotokoll Ende</Typography>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Box>
  );
};

export default MesurePrint;

// mesureDate: string;
// deviceSensor: string;
// sensorUnit: string;
// value: string;

import React from "react";
import { useStores } from "../stores/stores";
import { useAuth0 } from "@auth0/auth0-react";
import AddFireDepartment from "./AddFireDepartment";
import FireDepartmentDetail from "./FireDepartmentDetail";
import Box from "../common/components/atomics/Box";

const FireDepartmentView = (props: any) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const fireDepartment = useStores("remote", (data) => data.fireDepartment);
  if (isLoading) return <div>Loading ...</div>;
  if (!isAuthenticated) return <div>Authentication error</div>;

  return (
    <Box>
      {fireDepartment && <FireDepartmentDetail />}
      {!fireDepartment && <AddFireDepartment />}
    </Box>
  );
};

export default FireDepartmentView;

import React from "react";
import Grid from "../../common/components/atomics/Grid";
import TextField from "../../common/components/atomics/TextField";
import Button from "../../common/components/atomics/Button";

const AddFeedback = (props: any) => {
  const { item, handleChange, handleAddFeedback, disableSubmit } = props;
  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          multiline
          required
          rows="5"
          name="feedback"
          label="Meldungstext"
          value={item}
          fullWidth
          onChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" disabled={disableSubmit} onClick={(e) => handleAddFeedback()}>
          hinzufügen
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddFeedback;

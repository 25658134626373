import React from "react";

import VehicleEditListItem from "./VehicleEditListItem";
import VehicleCard from "./VehicleCard";
import Grid from "../../common/components/atomics/Grid";
import { IVehicle } from "../../model/vehicle";
import { IUnit } from "../../model/unit";

const VehicleEditList = (props: any) => {
  const { vehiclesList, crewsList, handleAddCrew, handleArrived, changeVehicle } = props;

  return (
    <Grid container item xs={12}>
      {vehiclesList.map((item: IVehicle, i: any) => (
        <VehicleCard
          changeVehicle={changeVehicle}
          key={item.id}
          item={item}
          crew={crewsList.filter((d: IUnit) => d.vehicleId == item.id)}
          handleAddCrew={handleAddCrew}
          handleArrived={handleArrived}
        ></VehicleCard>
      ))}
    </Grid>
  );
};

export default VehicleEditList;

// <Grid container>
// {vehiclesList.map((item, i) => (
//   <VehicleGroupItem
//     key={item.station}
//     item={item}
//     elements={item.vehicles.map((vehicle, o) => (
//       <VehicleListItem key={vehicle.id} number={o + 1} item={vehicle} />
//     ))}
//   ></VehicleGroupItem>
// ))}
// </Grid>

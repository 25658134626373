import { v4 as uuidv4 } from "uuid";
import { FeedbackPriority } from "./modeltypes";
import { IModelbase } from "./modelbase";

export interface IFeedback extends IModelbase {
  documentationId: string;
  date: string;
  feedback: string;
  priority: FeedbackPriority;
  vehicleBos: string;
}

export interface ISnackMessage {
  message: string;
  key: number;
}

export const newFeedback = (
  docId: string,
  feedback: string,
  priority: FeedbackPriority,
  vehicleBos: string | undefined
) => {
  return {
    id: uuidv4(),
    documentationId: docId,
    date: new Date().toLocaleString(),
    feedback: feedback,
    priority: priority,
    vehicleBos: vehicleBos,
  } as IFeedback;
};

export const newSnackMessage = (message: string) => {
  return { message: message, key: new Date().getTime() } as ISnackMessage;
};

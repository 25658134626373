import React, { forwardRef } from "react";
import { Grid as MuiGrid } from "@mui/material";
import { IGrid } from "./types";

const Grid = forwardRef<HTMLDivElement, IGrid>((props, ref) => {
  const { children, ...otherProps } = props;

  return (
    <MuiGrid {...otherProps} ref={ref}>
      {children}
    </MuiGrid>
  );
});

export default Grid;

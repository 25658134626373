import React from "react";
import Grid from "../common/components/atomics/Grid";

import Paper from "../common/components/atomics/Paper";
import Typography from "../common/components/atomics/Typography";
import { IDevice } from "../model/device";
import DeviceListItem from "./DeviceListItem";
import List from "../common/components/atomics/List";
import Button from "../common/components/atomics/Button";
import Divider from "../common/components/atomics/Divider";
import { useNavigate } from "react-router-dom";

const DeviceList = (props: any) => {
  const { devicesList, handleEdit } = props;

  let navigate = useNavigate();

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            m={1}
            fontSize={20}
            style={{
              textAlign: "center",
            }}
          >
            Geräteliste
          </Typography>
          <Divider style={{ marginBottom: "20px" }} />
        </Grid>
        <Grid item xs={12}>
          <List>
            {devicesList.map((item: IDevice, i: any) => (
              <DeviceListItem onEdit={handleEdit} key={item.id} number={1 + i} item={item}></DeviceListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Grid item m={1}>
            <Button onClick={() => navigate("/account/device/add")} variant="outlined">
              Neues Gerät hinzufügen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DeviceList;

import { UnitType } from "../../model/modeltypes";
import { IUnit, newUnit } from "../../model/unit";
import { stores, useStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const useUnit = () => {
  const selectedDocumentationId = useStores("ui", (data) => data.selectedDocumentationId);
  const clientList = useStores("documentation", (data) =>
    data.crews.filter((d) => d.documentationId == selectedDocumentationId && d.vehicleId == undefined)
  );

  const changeSnack = stores.ui.getState().update;
  const changeDocumentation = stores.documentation.getState().update;

  const addUnit = (docuId: string, unitType: UnitType, vehicleId?: string) => {
    changeDocumentation((data) => {
      data.crews.push(newUnit(docuId, unitType, vehicleId));
    });
  };

  const copyUnit = (unit: IUnit, docuId: string, vehicleId: string) => {
    let _unit = { ...unit };
    _unit.documentationId = docuId;
    _unit.vehicleId = vehicleId;
    _unit.id = uuidv4();
    return _unit;
    // changeDocumentation((data) => {
    //   data.crews.push(_unit);
    // });
  };

  const addClient = (client: IUnit) => {
    changeDocumentation((data) => {
      data.crews.push(client);
    });
  };

  const deleteUnit = (unit: IUnit) => {
    changeDocumentation((data: any) => {
      data.crews = data.crews.filter((d: IUnit) => d.id !== unit.id);
    });
  };

  return { clientList, addUnit, addClient, deleteUnit, copyUnit };
};

export default useUnit;

import { v4 as uuidv4 } from "uuid";
import { IModelbase } from "./modelbase";
import { UnitType } from "./modeltypes";
import { IAddress } from "./documentation";

export interface IUnit extends IModelbase {
  documentationId: string;
  unitType: UnitType;
  crewType: string;
  vehicleId?: string;
  canRespirator: boolean;
  address?: IAddress;
  note?: string;
  name: string;
}

export const newUnit = (docId: string, unitType: UnitType, vehicleId?: string) => {
  return {
    id: uuidv4(),
    documentationId: docId,
    vehicleId: vehicleId,
    unitType: unitType,
    crewType: "",
    canRespirator: false,
    name: "",
    note: unitType === "client" ? "" : undefined,
  } as IUnit;
};

import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { stores, useStores } from "../../stores/stores";
import { ITemplateDocumentation } from "../../model/templateDocumentation";
import { ApiCall } from "../../stores/api";
import useApi from "./useApi";
import { IFireDepartment } from "../../model/fireDepartment";
import { IMember } from "../../model/member";
import {
  AddFireDepartmentModel,
  DeleteFireDepartmentModel,
  DeleteMemberModel,
  FireDepartmentAddDeviceModel,
  FireDepartmentDeleteDeviceModel,
  FireDepartmentInviteMemberModel,
} from "../../model/api/apiModels";
import { IDevice } from "../../model/device";

const useManagementApi = () => {
  const changeRemote = stores.remote.getState().update;
  const changeCache = stores.cache.getState().update;
  const { getToken } = useApi();

  const addFireDepartment = async (name: string, deviceEmail: string, devicePassword: string) => {
    var model = {
      name: name,
      deviceEmail: deviceEmail,
      devicePassword: devicePassword,
    } as AddFireDepartmentModel;
    const apiManagement = new ApiCall<IFireDepartment>("apiManagementRoute", await getToken());

    var fd = await apiManagement.post(model, "firedepartment/add");
    if (fd)
      changeRemote((data: any) => {
        data.fireDepartment = fd;
      });
    return fd;
  };

  const updateFireDepartment = async (updateModel: IFireDepartment) => {
    const apiManagement = new ApiCall<IFireDepartment>("apiManagementRoute", await getToken());

    var fd = await apiManagement.update(updateModel, "firedepartment/update");
    if (fd)
      changeRemote((data: any) => {
        data.fireDepartment = fd;
      });
    return fd;
  };

  const deleteFireDepartment = async (id: string) => {
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var model = {
      fireDepartmentId: id,
    } as DeleteFireDepartmentModel;

    var fd = await apiManagement.delete(model, "firedepartment/delete");
    return fd;
  };

  const fireDepartmentAddDevice = async (fireDepartmentId: string, name: string) => {
    var model = {
      fireDepartmentId: fireDepartmentId,
      name: name,
    } as FireDepartmentAddDeviceModel;
    const apiManagement = new ApiCall<IFireDepartment>("apiManagementRoute", await getToken());

    var fd = await apiManagement.post(model, "firedepartment/device/add");
    return fd;
  };

  const fireDepartmentUpdateDevice = async (updateModel: IDevice) => {
    const apiManagement = new ApiCall<IDevice>("apiManagementRoute", await getToken());

    var fd = await apiManagement.update(updateModel, "firedepartment/device/update");
    return fd;
  };

  const fireDepartmentDeleteDevice = async (fireDepartmentId: string, deviceId: string) => {
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var model = {
      fireDepartmentId: fireDepartmentId,
      deviceId: deviceId,
    } as FireDepartmentDeleteDeviceModel;

    var fd = await apiManagement.delete(model, "firedepartment/delete");
    return fd;
  };

  const fireDepartmentInviteMember = async (email: string, fireDepartmentId: string) => {
    var model = {
      fireDepartmentId: fireDepartmentId,
      userEmail: email,
      userId: "",
    } as FireDepartmentInviteMemberModel;
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var fd = await apiManagement.post(model, "firedepartment/member/invite");
    return fd;
  };

  const fireDepartmentInviteAcceptMember = async (userId: string, fireDepartmentId: string) => {
    var model = {
      fireDepartmentId: fireDepartmentId,
      userId: userId,
    } as FireDepartmentInviteMemberModel;
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var fd = await apiManagement.post(model, "firedepartment/member/invite");
    return fd;
  };

  const fireDepartmentRemoveMember = async (userId: string, fireDepartmentId: string) => {
    var model = {
      fireDepartmentId: fireDepartmentId,
      userId: userId,
    } as FireDepartmentInviteMemberModel;
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var fd = await apiManagement.post(model, "firedepartment/member/remove");
    return fd;
  };

  const updateMember = async (updateModel: IMember) => {
    const apiManagement = new ApiCall<IFireDepartment>(
      "apiManagementRoute",

      await getToken()
    );

    var entity = await apiManagement.update(updateModel, "member/update");
    return entity;
  };

  const deleteMember = async (userId: string, memberId: string) => {
    const apiManagement = new ApiCall<boolean>("apiManagementRoute", await getToken());

    var model = {
      userId: userId,
      memberId: memberId,
    } as DeleteMemberModel;

    var fd = await apiManagement.delete(model, "member/delete");
    return fd;
  };

  const pullFireDepartment = async () => {
    const apiManagement = new ApiCall<IFireDepartment>(
      "apiManagementRoute",

      await getToken()
    );
    var fd = await apiManagement.call("firedepartment/getown");

    if (fd)
      changeRemote((data: any) => {
        data.fireDepartment = fd;
      });
  };

  const pullMember = async () => {
    const apiManagement = new ApiCall<IMember>("apiManagementRoute", await getToken());
    var member = await apiManagement.call("member/getown");

    if (member)
      changeCache((data: any) => {
        data.member = member;
      });
  };

  const pullDevice = async () => {
    const apiManagement = new ApiCall<IMember>("apiManagementRoute", await getToken());
    var device = await apiManagement.call("device/getown");

    if (device)
      changeCache((data: any) => {
        data.device = device;
      });
  };

  const copyTemplatesMesureDevice = async () => {
    const apiManagement = new ApiCall<boolean>("apiStaticRoute", await getToken());
    await apiManagement.call("templates/gen-md");
    await apiManagement.call("templates/gen-doc");
  };

  return {
    addFireDepartment,
    updateFireDepartment,
    deleteFireDepartment,
    fireDepartmentAddDevice,
    fireDepartmentUpdateDevice,
    fireDepartmentDeleteDevice,
    fireDepartmentInviteMember,
    fireDepartmentInviteAcceptMember,
    fireDepartmentRemoveMember,
    updateMember,
    deleteMember,
    pullFireDepartment,
    pullMember,
    pullDevice,
    copyTemplatesMesureDevice,
  };
};

export default useManagementApi;

import React from "react";
import ListItem from "../../common/components/atomics/ListItem";
import ListItemText from "../../common/components/atomics/ListItemText";
import ListItemAvatar from "../../common/components/atomics/ListItemAvatar";
import Badge from "../../common/components/atomics/Badge";
import Typography from "../../common/components/atomics/Typography";

const FeedbackListItem = (props: any) => {
  const { item, number } = props;
  return (
    <ListItem
      divider
      secondaryAction={item.vehicleBos && <ListItemText primary="von:" secondary={item.vehicleBos}></ListItemText>}
    >
      <ListItemAvatar>
        <Badge badgeContent={number} color="primary" />
      </ListItemAvatar>

      <ListItemText secondary={item.date}>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>{item.feedback}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export default FeedbackListItem;

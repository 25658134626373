import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "../../common/components/atomics/IconButton";
import Grid from "../../common/components/atomics/Grid";
import Card from "../../common/components/atomics/Card";
import Avatar from "../../common/components/atomics/Avatar";
import Tooltip from "../../common/components/atomics/Tooltip";
import CardContent from "../../common/components/atomics/CardContent";
import CardActions from "../../common/components/atomics/CardActions";
import CardHeader from "../../common/components/atomics/CardHeader";
import { IUnit } from "../../model/unit";

const UnitCard = (props: any) => {
  const { mainContent, expandContent, deleteable, handleDelete, isForm } = props;
  const unit = props.unit as IUnit;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item>
      <Card sx={{ minWidth: 250, maxWidth: 345 }}>
        <CardHeader
          avatar={<Avatar aria-label="recipe">{unit.name.charAt(0).toUpperCase()}</Avatar>}
          subheader={deleteable ? unit.name : "neue Person"}
          action={
            !isForm && (
              <Tooltip title="Personbegleitkarte erzeugen">
                <IconButton>
                  <AssignmentIndIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent>{expanded ? expandContent : mainContent}</CardContent>
        <CardActions disableSpacing>
          {!isForm && (
            <Tooltip title="Person editieren" onClick={handleExpandClick}>
              <IconButton aria-label="edit" color={expanded ? "primary" : "inherit"}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {deleteable && (
            <Tooltip title="Person löschen">
              <div>
                <IconButton disabled={expanded ? false : true} onClick={() => handleDelete(unit)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default UnitCard;

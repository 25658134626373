import React from "react";
import { AppBar as MuiAppBar } from "@mui/material";
import { IAppBar } from "./types";

const AppBar = React.forwardRef<HTMLDivElement, IAppBar>(({ children, ...props }, ref) => {
  return (
    <MuiAppBar ref={ref} {...props}>
      {children}
    </MuiAppBar>
  );
});

export default AppBar;

import "./App.css";
import { Box, Button, ThemeProvider } from "@mui/material";
import { Stack } from "@mui/system";
import { PersistGate } from "@colorfy-software/zfy";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { React, Suspense, useState } from "react";
import DashboardView from "./common/DashboardView";
import DocumentationList from "./documentation/components/DocumentationList";
import DocumentationDetail from "./documentation/components/DocumentationDetail";
import Paperbase from "./common/Paperbase";
import DocumentationNew from "./documentation/components/DocumentationNew";
import { storeA, storeB, storeUi } from "./stores/stores";
import DocumentationView from "./documentation/components/DocumentationView";
import MesurePrint from "./mesurement/components/MesurePrint";
import SettingsView from "./common/SettingsView";
import DocumentationPrint from "./documentation/components/DocumentationPrint";
import baseTheme from "./theme";
import UnitPrint from "./unit/components/UnitPrint";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import Login from "./common/components/Login";
import WelcomeView from "./common/WelcomeView";
import ProfileView from "./common/ProfileView";
import AddDevice from "./Management/AddDevice";
import InviteMember from "./Management/InviteMember";
import MemberDetail from "./Management/MemberDetail";
import DeviceDetail from "./Management/DeviceDetail";
import TemplateDocumentationDetail from "./Management/TemplateDocumentationDetail";

const App = () => {
  // const { currentTheme } = useTheme();
  const [theme, setTheme] = useState(baseTheme);

  const ProtectedRoute = ({ component, ...args }) => {
    const Component = withAuthenticationRequired(component, args);
    return <Component />;
  };

  const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
      navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (
      <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
        {children}
      </Auth0Provider>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain="dev-ded.eu.auth0.com"
          clientId="qesqK7QBM1gYTZ9K8g2wD2m7WDS1cLnq"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://localhost:8081/api/",
          }}
        >
          <PersistGate stores={[storeA, storeB, storeUi]} loader={<div>Loading...</div>}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Paperbase />}>
                  <Route path="/" element={<WelcomeView />} />
                  <Route path="/dashboard" element={<ProtectedRoute component={DashboardView} />} />
                  <Route path="/settings" element={<ProtectedRoute component={SettingsView} />} />
                  <Route path="/docu" element={<ProtectedRoute component={DocumentationView} />} />
                  <Route path="/docu/new" element={<ProtectedRoute component={DocumentationNew} />} />
                  <Route path="/docu/list" element={<ProtectedRoute component={DocumentationList} />} />
                  <Route path="/docu/detail" element={<ProtectedRoute component={DocumentationDetail} />} />
                  <Route path="/account" element={<ProtectedRoute component={ProfileView} />} />
                  <Route path="/account/device/add" element={<ProtectedRoute component={AddDevice} />} />
                  <Route path="/account/device/:id" element={<ProtectedRoute component={DeviceDetail} />} />
                  <Route
                    path="/account/member/invite"
                    element={<ProtectedRoute component={InviteMember} />}
                  />
                  <Route path="/account/member/:id" element={<ProtectedRoute component={MemberDetail} />} />
                  <Route
                    path="/account/templates/documentation/:id"
                    element={<ProtectedRoute component={TemplateDocumentationDetail} />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                      </main>
                    }
                  />
                </Route>
                <Route path="/docu/detail/print" element={<DocumentationPrint />} />
                <Route path="/docu/detail/mesure/print" element={<MesurePrint />} />
                <Route path="/docu/detail/unit/print" element={<UnitPrint />} />
              </Routes>
            </Suspense>
          </PersistGate>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;

import React from "react";
import Grid from "../../common/components/atomics/Grid";
import TextField from "../../common/components/atomics/TextField";

const Address = (props: any) => {
  const { address, handleChange } = props;
  return (
    <Grid container spacing={2} rowSpacing={2}>
      {/* <Grid item xs={12}>
        <Typography>Addressdaten</Typography>
      </Grid> */}
      <Grid item xs={8}>
        <TextField
          name="street"
          label="Straße"
          value={address.street}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="houseNumber"
          label="Hausnummer"
          value={address.houseNumber}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="district"
          label="Ortsteil"
          value={address.district}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="city"
          label="Ort"
          value={address.city}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e)}
        />
      </Grid>
    </Grid>
  );
};

export default Address;

import React, { useState } from "react";
import UnitListItemCard from "./UnitListItemCard";
import useUnit from "../hooks/useUnit";
import AddUnitCard from "./AddUnitCard";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import IconButton from "../../common/components/atomics/IconButton";
import Grid from "../../common/components/atomics/Grid";
import Paper from "../../common/components/atomics/Paper";
import { IUnit } from "../../model/unit";

const UnitListCard = (props: any) => {
  const [isWriting, setIsWriting] = useState(false);
  const { clientList } = useUnit();
  return (
    <Grid container item style={{ maxHeight: "800px" }} spacing={2} rowSpacing={2}>
      {clientList
        .map((item: IUnit, i: any) => <UnitListItemCard key={item.id} number={i + 1} unit={item} />)
        .concat(
          isWriting == false ? (
            <Grid item key="addUnitCard">
              <Paper elevation={1}>
                <IconButton
                  onClick={() => setIsWriting(true)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px",
                    height: "150px",
                    borderRadius: "10px",
                  }}
                >
                  <PersonAddAlt1Icon sx={{ width: 80, height: 80 }} />
                </IconButton>
              </Paper>
            </Grid>
          ) : (
            <AddUnitCard key="newCard" handleReset={() => setIsWriting(false)} />
          )
        )}
    </Grid>
  );
};

export default UnitListCard;

import { v4 as uuidv4 } from "uuid";
import { IModelbase } from "./modelbase";
import { IMesure } from "./mesure";
import { IMesureDevice } from "./templateMesureDevice";

export interface IMesurement extends IModelbase {
  documentationId: string;
  startDate: string;
  device: IMesureDevice;
  deviceNumber: string;
  searchTarget: string;
  searchPoints: string[];
  note: string;
  crew?: string;
  crewIds?: string[];
  mesureValues: IMesure[];
}

export const newMesurement = (docId: string, device: IMesureDevice) => {
  return {
    id: uuidv4(),
    documentationId: docId,
    startDate: new Date().toLocaleString(),
    device: device,
    deviceNumber: "",
    searchTarget: "",
    searchPoints: [],
    note: "",
    crew: "",
    crewIds: [],
    mesureValues: [],
  } as IMesurement;
};

import React from "react";
import Grid from "../common/components/atomics/Grid";

import Paper from "../common/components/atomics/Paper";
import Typography from "../common/components/atomics/Typography";
import List from "../common/components/atomics/List";
import Button from "../common/components/atomics/Button";
import Divider from "../common/components/atomics/Divider";
import { useNavigate } from "react-router-dom";
import TemplateDocumentationListItem from "./TemplateDocumentationListItem";
import { ITemplateDocumentation } from "../model/templateDocumentation";

const TemplateDocumentationList = (props: any) => {
  const { templatesList, handleEdit } = props;

  let navigate = useNavigate();

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            m={1}
            fontSize={20}
            style={{
              textAlign: "center",
            }}
          >
            Template Liste
          </Typography>
          <Divider style={{ marginBottom: "20px" }} />
        </Grid>
        <Grid item xs={12}>
          <List>
            {templatesList &&
              templatesList.map((item: ITemplateDocumentation, i: any) => (
                <TemplateDocumentationListItem
                  onEdit={handleEdit}
                  key={item.id}
                  number={1 + i}
                  item={item}
                ></TemplateDocumentationListItem>
              ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Grid item m={1}>
            <Button onClick={() => navigate("/account/templates/documentation/add")} variant="outlined">
              Neues Template erstellen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateDocumentationList;

import React from "react";
import { ListItemButton as MuiListItemButton } from "@mui/material";
import { IListItemButton } from "./types";

const ListItemButton = React.forwardRef<HTMLDivElement, IListItemButton>(({ children, ...props }, ref) => {
  return (
    <MuiListItemButton ref={ref} {...props}>
      {children}
    </MuiListItemButton>
  );
});

export default ListItemButton;

import React from "react";
import { useNavigate } from "react-router-dom";
import MesurementListItem from "./MesurementListItem";
import Grid from "../../common/components/atomics/Grid";
import { IMesurement } from "../../model/mesurement";

const MesurementList = (props: any) => {
  const { mesurementList, onClick } = props;
  const navigate = useNavigate();
  return (
    <Grid container item xs={12}>
      {mesurementList.map((item: IMesurement, i: any) => (
        <MesurementListItem key={item.id} number={i + 1} item={item} onClick={onClick} />
      ))}
    </Grid>
  );
};

export default MesurementList;

import React, { useState } from "react";
import { stores, useStores } from "../../stores/stores";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import useVehicle from "./../../vehicle/hooks/useVehicle";
import DocumentationDetail from "./DocumentationDetail";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import BiotechIcon from "@mui/icons-material/Biotech";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MesureView from "./../../mesurement/components/MesureView";
import VehiclesView from "./../../vehicle/components/VehiclesView";
import UnitsView from "../../unit/components/UnitsView";
import IconButton from "../../common/components/atomics/IconButton";
import Grid from "../../common/components/atomics/Grid";
import AppBar from "../../common/components/atomics/AppBar";
import ListItemAvatar from "../../common/components/atomics/ListItemAvatar";
import Avatar from "../../common/components/atomics/Avatar";
import Tab from "../../common/components/atomics/Tab";
import ListItem from "../../common/components/atomics/ListItem";
import ListItemButton from "../../common/components/atomics/ListItemButton";
import Tooltip from "../../common/components/atomics/Tooltip";
import Stack from "../../common/components/atomics/Stack";
import ListItemText from "../../common/components/atomics/ListItemText";
import Toolbar from "../../common/components/atomics/Toolbar";
import { IDocumentation } from "../../model/documentation";

const DocumentationView = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const selectedDocumentationId = useStores("ui", (data: any) => data.selectedDocumentationId);
  const documentation = useStores(
    "documentation",
    (data: any) => data.documentations.find((d: any) => d.id == selectedDocumentationId)!
  );

  const changeDocumentation = stores.documentation.getState().update;

  const handleChangeTab = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  const { vehiclesList, crewsList } = useVehicle();

  return (
    <Grid>
      <TabContext value={tabValue}>
        <Grid container>
          <AppBar position="sticky" color="inherit">
            <Toolbar>
              <ListItemAvatar>
                <Tooltip title="Zurück">
                  <IconButton onClick={() => navigate("/docu/list")}>
                    <Avatar>
                      <ArrowBackIcon />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </ListItemAvatar>
              <Grid
                item
                xs={(documentation.modules.vehicleManager ? 3 : 0) + (documentation.modules.mesure ? 2 : 0) + 3}
              >
                <TabList onChange={handleChangeTab} aria-label="lab">
                  <Tab label="Allgemein" value="1" />
                  {documentation.modules.vehicleManager && <Tab label="Einsatzkräfte" value="2" />}
                  {documentation.modules.vehicleManager && <Tab label="Personen" value="3" />}
                  {documentation.modules.mesure && <Tab label="Messprotokolle" value="4" />}
                </TabList>
              </Grid>
              <Grid
                item
                xs={(documentation.modules.vehicleManager ? 0 : 3) + (documentation.modules.mesure ? 0 : 2) + 3}
              >
                <ListItem dense disablePadding>
                  {!documentation.modules.vehicleManager ? (
                    <Tooltip title="aktiviert das Modul Einsatzkräfte um Fahrzeuge/Einsatzkräfte zu verwalten">
                      <ListItemButton
                        onClick={() =>
                          changeDocumentation((data: any) => {
                            data.documentations.find(
                              (d: IDocumentation) => d.id == documentation.id
                            ).modules.vehicleManager = true;
                          })
                        }
                      >
                        <ListItemAvatar>
                          <MinorCrashIcon />
                        </ListItemAvatar>
                        <ListItemText primary="Modul Einsatzkräfte" secondary="verwenden" />
                      </ListItemButton>
                    </Tooltip>
                  ) : (
                    <ListItemText />
                  )}
                  {!documentation.modules.mesure ? (
                    <Tooltip title="aktiviert das Modul Messprotokoll um Messprotokolle anzufertigen">
                      <ListItemButton
                        onClick={() =>
                          changeDocumentation((data: any) => {
                            data.documentations.find((d: IDocumentation) => d.id == documentation.id).modules.mesure =
                              true;
                          })
                        }
                      >
                        <ListItemAvatar>
                          <BiotechIcon />
                        </ListItemAvatar>
                        <ListItemText primary="Modul Messprotokoll" secondary="verwenden" />
                      </ListItemButton>
                    </Tooltip>
                  ) : (
                    <ListItemText />
                  )}
                  <Stack direction={"row"}>
                    <Grid container alignSelf={"flex-end"}>
                      <Grid item alignSelf={"center"}>
                        <Tooltip title="Schnellinfo">
                          <IconButton color="inherit">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item m={1} alignSelf={"center"}>
                        <ListItemText secondary={vehiclesList.length} primary="Fahrzeuge" />
                      </Grid>
                      <Grid item m={1} alignSelf={"center"}>
                        <ListItemText secondary={crewsList.length} primary="Einsatzkräfte" />
                      </Grid>
                      <Grid item m={1} alignSelf={"center"}>
                        <ListItemText
                          secondary={crewsList.filter((d) => d.canRespirator).length}
                          primary="Atemschutz"
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </ListItem>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
        <TabPanel value="1">
          <DocumentationDetail />
        </TabPanel>
        <TabPanel value="2">
          <VehiclesView />
        </TabPanel>
        <TabPanel value="3">
          <UnitsView />
        </TabPanel>
        <TabPanel value="4">
          <MesureView />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default DocumentationView;

import { Card, CardActionArea, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./atomics/Button";
import Grid from "./atomics/Grid";

const DashboardCard = (props: any) => {
  const { headline, description, linkName, path, img } = props;
  let navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {headline}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button color="secondary" variant="outlined" onClick={() => navigate(`/${path}`)}>
            {linkName}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default DashboardCard;

import { v4 as uuidv4 } from "uuid";
import { IModelbase } from "./modelbase";
import { OrganisationTypes } from "./modeltypes";

export interface IVehicle extends IModelbase {
  documentationId: string;
  provisionAreaId?: string;
  vehicleType: OrganisationTypes;
  vehicleName: string;
  bos: string;
  station: string;
  isArrived: boolean;
  isRemoved: boolean;
}

export const newVehicle = (
  docId: string,
  vType: OrganisationTypes,
  name: string,
  bos?: string,
  arrived?: boolean,
  station?: string,
  provisionAreaId?: string
) => {
  return {
    id: uuidv4(),
    documentationId: docId,
    vehicleType: vType,
    vehicleName: name,
    isArrived: arrived,
    isRemoved: false,
    bos: bos ? bos : "",
    station: station,
    provisionAreaId: provisionAreaId,
  } as IVehicle;
};

export const copyVehicle = (vehicle: IVehicle) => {
  var _vehicle = { ...vehicle };
  _vehicle.id = uuidv4();
  return _vehicle as IVehicle;
};

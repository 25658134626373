import React, { useState } from "react";
import Box from "../common/components/atomics/Box";
import Paper from "../common/components/atomics/Paper";
import Grid from "../common/components/atomics/Grid";
import TextField from "../common/components/atomics/TextField";
import Typography from "../common/components/atomics/Typography";
import Button from "../common/components/atomics/Button";
import useManagementApi from "../common/hooks/useManagementApi";
import { stores, useStores } from "../stores/stores";
import MemberList from "./MemberList";
import DeviceList from "./DeviceList";
import InviteList from "./InviteList";
import Stack from "../common/components/atomics/Stack";
import TemplateDocumentationList from "./TemplateDocumentationList";

const FireDepartmentManageView = () => {
  const { updateFireDepartment } = useManagementApi();
  const fireDepartment = useStores("remote", (data) => data.fireDepartment!);
  const templatesDocus = useStores("remote", (data) => data.documentationTemplates);
  const changeRemote = stores.remote.getState().update;

  return (
    <Box sx={{ height: "100vh" }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          Feuerwehr Verwalten
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="Members"
              label="Mitgliederanzahl"
              value={fireDepartment.members.length}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="Geräte"
              label="Geräteanzahl"
              value={fireDepartment.devices.length}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <MemberList membersList={fireDepartment.members} />
              {fireDepartment.invites.length > 0 && <InviteList invitesList={fireDepartment.invites} />}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <DeviceList devicesList={fireDepartment.devices} />
          </Grid>
          <Grid item xs={6}>
            <TemplateDocumentationList templatesList={templatesDocus} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default FireDepartmentManageView;

import { Box, List, Paper, Grid } from "@mui/material";
import React from "react";

import VehicleListItem from "./VehicleListItem";
import { IVehicle } from "../../model/vehicle";
import { IUnit } from "../../model/unit";

const VehicleList = (props: any) => {
  const { vehiclesList, crewsList, handleAddVehicleFeedback, handleArrived } = props;

  return (
    <Grid container item xs={12}>
      {vehiclesList.filter((d: IVehicle) => d.isRemoved == false).length > 0
        ? vehiclesList
            .filter((d: IVehicle) => d.isRemoved == false)
            .map((item: IVehicle, i: any) => (
              <VehicleListItem
                key={item.id}
                item={item}
                crew={crewsList.filter((d: IUnit) => d.vehicleId == item.id)}
                handleArrived={handleArrived}
                handleAddVehicleFeedback={handleAddVehicleFeedback}
              ></VehicleListItem>
            ))
        : "Keine Fahrzeuge vorhanden"}
    </Grid>
  );
};

export default VehicleList;
